import React from "react";
import { Button } from "reactstrap";

const PageNotFound = () => {
  return (
    <div
      style={{ height: "70vh" }}
      class="d-flex align-items-center justify-content-center"
    >
      <div class="text-center">
        <h1 class="display-1 fw-bold">404</h1>
        <p class="fs-3">
          <span class="text-danger">Opps!</span> Coś poszło nie tak.
        </p>
        <p class="lead">Strona której szukasz nie istnieje.</p>
        <a href="/">
          <Button>Stona główna</Button>
        </a>
      </div>
    </div>
  );
};

export default PageNotFound;
