import React from "react";
import { Card, CardBody } from "reactstrap";
import { BsFillTelephoneFill } from "react-icons/bs";
import { HiMail } from "react-icons/hi";
import { HiOutlineArrowLongRight } from "react-icons/hi2";

const Contact = () => {
  return (
    <div>
      <h3>
        Kontakt
        <p
          style={{
            fontSize: 15,
            color: "#403F6F",
            textTransform: "none",
          }}
          className="mb-0"
        >
          <HiOutlineArrowLongRight size={24} /> jeśli potrzebujesz pomocy
        </p>
      </h3>
      <Card>
        <CardBody>
          <div
            className="d-flex justify-content-around"
            style={{
              with: "100%",
              fontSize: 16,
            }}
          >
            <address>
              <div>
                <strong>Dorota Pieczyńska</strong>
              </div>
              <div>
                <BsFillTelephoneFill
                  className="icon-contact"
                  style={{ marginRight: "13px" }}
                />
                790 203 830
              </div>
              <div>
                <HiMail size={19} className="icon-contact" />
                <a
                  className="text-dark text-decoration-none"
                  href="mailto:dorota.pieczynska@polanex.com.pl"
                >
                  dorota.pieczynska@polanex.com.pl
                </a>
              </div>
            </address>
            <address>
              <strong>Polanex sp. z o. o.</strong>
              <br />
              <p className="mb-0">
                Ul. Słoneczna 40 <br /> 62-200 Gniezno <br />
              </p>
            </address>
          </div>
        </CardBody>
      </Card>
    </div>
  );
};

export default Contact;
