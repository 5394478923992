import React, { useContext, useEffect } from "react";
import { Route, Routes } from "react-router-dom";
import axios from "axios";
import AppRoutes from "./AppRoutes";
import AppRoutesUnauthorized from "./AppRoutesUnauthorized";
import { AuthContext } from "./components/context/GlobalState";
import "./custom.css";

const AppContainer = () => {
  const [auth, setAuth] = useContext(AuthContext);

  useEffect(() => {
    if (auth === undefined || auth === null) {
      axios
        .get("/api/account/is-authorized")
        .then((response) => {
          setAuth({ authorized: true, ...response.data });
        })
        .catch((error) => {
          setAuth({ authorized: false });
        });
    }
  }, [auth, setAuth]);

  if (auth === undefined || auth === null) return null;

  if (auth.authorized === false)
    return (
      <Routes>
        {AppRoutesUnauthorized.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}
      </Routes>
    );

  return (
    <Routes>
      {AppRoutes.map((route, index) => {
        const { element, ...rest } = route;
        return <Route key={index} {...rest} element={element} />;
      })}
    </Routes>
  );
};

export default AppContainer;
