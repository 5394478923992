import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Col,
  Label,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";

const OrderItemAddModal = ({
  orderId,
  setModalVisible,
  visible,
  addOrderItem,
}) => {
  const [fullName, setFullName] = useState("");
  const [gender, setGender] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleCancel = () => {
    setErrorMessage(null);
    setModalVisible(false);
    setFullName("");
    setGender("");
  };

  const handleSubmit = () => {
    setErrorMessage(null);
    axios
      .post("/api/order-items/", {
        orderId,
        fullName,
        gender,
      })
      .then((response) => {
        addOrderItem({
          id: response.data,
          orderId,
          fullName,
          gender,
        });
        setModalVisible(false);
        setFullName("");
        setGender("");
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  if(!visible) return null;

  return (
    <div>
      <Modal isOpen={visible}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label sm={3} for="Name">
                Imię i nazwisko
              </Label>
              <Col>
                <Input
                  name="fullName"
                  type="text"
                  value={fullName}
                  onChange={(event) => setFullName(event.target.value)}
                />
              </Col>
            </FormGroup>
            <FormGroup style={{ padding: "0 0 15px 0" }} row>
              <legend className="col-form-label col-sm-2">Płeć</legend>
              <Col>
                <FormGroup check>
                  <Label check>
                    Kobieta
                    <Input
                      name="gender"
                      type="radio"
                      value="female"
                      onChange={(event) => setGender(event.target.value)}
                    />
                  </Label>
                </FormGroup>
                <FormGroup check>
                  <Label check>
                    Mężczyzna
                    <Input
                      name="gender"
                      type="radio"
                      value="male"
                      onChange={(event) => setGender(event.target.value)}
                    />
                  </Label>
                </FormGroup>
              </Col>
            </FormGroup>
            {errorMessage && (
              <FormGroup>
                <span style={{ color: "red" }}>{errorMessage}</span>
              </FormGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={handleCancel}>
            Anuluj
          </Button>
          <Button onClick={handleSubmit}>Zapisz</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OrderItemAddModal;
