import { ALL_PARAMETERS } from "../models/OrderItemParameters";

const OrderItemInventory = ({ inventory }) => {
  const elements = Object.keys(inventory).map((key) => {
    if (
      inventory[key] === null ||
      typeof inventory[key] === "undefined" ||
      inventory[key] === "" ||
      inventory[key] === false
    )
      return null;

    const parameter = ALL_PARAMETERS.find((x) => x.name === key);
    if (typeof parameter === 'undefined') return null;
    
    return (
      <span key={key}>
        <b>{`${inventory[key+"Amount"]}x ${parameter.label}`} {inventory[key] !== true && ` roz. ${inventory[key]}`}</b><br></br>
        Uwagi: {typeof inventory[key+"Comments"] !== 'undefined' && inventory[key+"Comments"] !== null && inventory[key+"Comments"] !== "" ? inventory[key+"Comments"] : "Brak" }
        <br></br>
      </span>
    );
  }).filter(x => x !== null);

  if(elements.length === 0) return "Brak";

  return elements;
};

export default OrderItemInventory;
