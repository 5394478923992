const ALL_PARAMETERS = [
  {
    name: "shirt",
    label: "Koszula (Regular)",
  },
  {
    name: "shirtSlim",
    label: "Koszula (Slim)",
  },
  {
    name: "jacket",
    label: "Marynarka",
  },
  {
    name: "pants",
    label: "Spodnie",
  },
  {
    name: "tie",
    label: "Krawat",
  },
  {
    name: "jacketW",
    label: "Żakiet",
  },
  {
    name: "shirtW",
    label: "Bluzka",
  },
  {
    name: "skirt",
    label: "Spódnica",
  },
  {
    name: "pantsW",
    label: "Spodnie",
  },
];

const MALE_PARAMETERS = [
  {
    name: "shirt",
    label: "Koszula (Regular)",
    sizes: [39, 40, 41, 42, 43, 44, 45, 46, 47, 48, 49, 50],
  },
  {
    name: "shirtSlim",
    label: "Koszula (Slim)",
    sizes: [39, 40, 41, 42, 43, 44, 45, 46],
  },
  {
    name: "jacket",
    label: "Marynarka",
    sizes: [50, 52, 54, 56, 58, 60],
  },
  {
    name: "pants",
    label: "Spodnie",
    sizes: [50, 52, 54, 56, 58, 60],
  },
];

const FEMALE_PARAMETERS = [
  {
    name: "jacketW",
    label: "Żakiet",
    sizes: [34, 36, 38, 40, 42, 44, 46, 48, 50, 52],
  },
  {
    name: "shirtW",
    label: "Bluzka",
    sizes: [34, 36, 38, 40, 42, 44, 46, 48, 50, 52],
  },
  {
    name: "skirt",
    label: "Spódnica",
    sizes: [34, 36, 38, 40, 42, 44, 46, 48, 50, 52],
  },
  {
    name: "pantsW",
    label: "Spodnie",
    sizes: [34, 36, 38, 40, 42, 44, 46, 48, 50, 52],
  },
];

export { ALL_PARAMETERS, MALE_PARAMETERS, FEMALE_PARAMETERS };
