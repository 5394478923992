import React, { useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Button, FormGroup, Modal, ModalBody, ModalFooter } from "reactstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { FaEdit } from "react-icons/fa";
import { MdAdd, MdDeleteOutline } from "react-icons/md";
import axios from "axios";

import { AuthContext, GlobalContext } from "../context/GlobalState";

const AdminBranchList = () => {
  const [auth] = useContext(AuthContext);
  const [modalVisible, setModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const { branches, initBranches, removeBranch } = useContext(GlobalContext);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    axios
      .get("/api/admin/branches")
      .then((response) => {
        initBranches(response.data);
      })
      .catch((error) => {
        setErrorMessage("Coś poszło nie tak. Przeładuj stronę.");
      });
  }, []);

  const handleRemoveModal = (item) => {
    setCurrentItem(item);
    setModalVisible(true);
  };

  const handleRemoveBranch = () => {
    setErrorMessage(null);
    axios
      .post("/api/admin/branches/delete", { id: currentItem.id })
      .then((response) => {
        removeBranch(currentItem.id);
        setModalVisible(false);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  const cancelRemoveBranch = () => {
    setModalVisible(false);
    setErrorMessage(null);
    setCurrentItem(null);
  };

  if (branches === null && errorMessage === null) return null;
  if (branches === null && errorMessage !== null)
    return (
      <FormGroup>
        <span style={{ color: "red" }}>{errorMessage}</span>
      </FormGroup>
    );

  return (
    <div>
      <h3>
        Wszystkie oddziały
        <p
          style={{
            fontSize: 15,
            color: "#403F6F",
            textTransform: "none",
          }}
          className="mb-0"
        >
          <HiOutlineArrowLongRight size={24} /> zarządzaj oddziałami
        </p>
      </h3>
      {auth?.isAdmin && (
        <div
          style={{ height: "40px" }}
          className="d-flex justify-content-between mb-3"
        >
          <div></div>
          <Link to="/admin/oddzialy/dodaj">
            <Button>
              <MdAdd className="btn-icon" />
              Dodaj oddział
            </Button>
          </Link>
        </div>
      )}
      <table className="table table-striped">
        <thead className="table-light">
          <tr>
            <th>Nr</th>
            <th>Oddział</th>
            <th>Kod</th>
            <th>Miasto</th>
            <th>Ulica</th>
            <th style={{ textAlign: "center" }}>
              <HiDotsHorizontal />
            </th>
            {auth?.isAdmin && <th>Admin</th>}
          </tr>
        </thead>
        <tbody>
          {branches &&
            branches.length > 0 &&
            branches.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.name}</td>
                  <td>{item.postCode}</td>
                  <td>{item.city}</td>
                  <td>{item.address}</td>
                  <td style={{ textAlign: "center" }}>
                    <Link
                      to={`/admin/oddzialy/${item.id}/uzytkownicy/`}
                      state={{ item }}
                    >
                      <Button style={{ margin: "0 5px 0 0" }}>
                        Użytkownicy
                      </Button>
                    </Link>
                    <Link
                      to={`/admin/oddzialy/${item.id}/zamowienia/`}
                      state={{ item }}
                    >
                      <Button style={{ margin: 0 }}>Zamówienia</Button>
                    </Link>
                  </td>
                  {auth?.isAdmin && (
                    <td>
                      <div
                        className="d-flex align-items-center"
                        style={{ height: "100%" }}
                      >
                        <Link
                          to={`/admin/oddzialy/edytuj/${item.id}`}
                          state={{ item }}
                        >
                          <FaEdit
                            style={{ paddingTop: "5px", width: "23px" }}
                            className="table-icon"
                          />
                        </Link>
                        <MdDeleteOutline
                          className="table-icon"
                          onClick={() => handleRemoveModal(item)}
                          style={{ margin: 0 }}
                        />
                      </div>
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
      {(!branches || branches.length === 0) && (
        <div className="d-flex flex-column justify-content-center align-items-center">
          <p>Tu jeszcze nic nie ma</p>
        </div>
      )}
      <Modal isOpen={modalVisible}>
        <ModalBody>
          {currentItem !== null && (
            <>
              Czy na pewno chcesz usunąć oddział<br></br>
              <b>{currentItem.name}</b>?<br></br>
              Usunięcie oddziału usunie wszystkie (aktywne i zamknięte)
              zamówienia i powiązanych użytkowników.
              {errorMessage && (
                <FormGroup>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                </FormGroup>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={cancelRemoveBranch}>
            Anuluj
          </Button>
          <Button onClick={handleRemoveBranch}>Akceptuj</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdminBranchList;
