import React, { createContext, useReducer, useState } from "react";
import AppReducer from "./AppReducer";

const initialState = {
  branches: [],
  orders: [],
  adminOrders: []
};

export const GlobalContext = createContext(initialState);
export const AuthContext = createContext();

export const GlobalProvider = ({ children }) => {
  const [state, dispatch] = useReducer(AppReducer, initialState);
  const [auth, setAuth] = useState(undefined);

  const initBranches = (data) => {
    dispatch({
      type: "INIT_BRANCHES",
      payload: data,
    });
  };

  const addBranch = (branch) => {
    dispatch({
      type: "ADD_BRANCH",
      payload: branch,
    });
  };

  const editBranch = (branch) => {
    dispatch({
      type: "EDIT_BRANCH",
      payload: branch,
    });
  };

  const removeBranch = (id) => {
    dispatch({
      type: "REMOVE_BRANCH",
      payload: id,
    });
  };

  const initOrders = (data) => {
    dispatch({
      type: "INIT_ORDERS",
      payload: data,
    });
  };

  const addOrder = (order) => {
    dispatch({
      type: "ADD_ORDER",
      payload: order,
    });
  };

  const editOrder = (order) => {
    dispatch({
      type: "EDIT_ORDER",
      payload: order,
    });
  };

  const submitOrder = (id) => {
    dispatch({
      type: "SUBMIT_ORDER",
      payload: id,
    });
  };

  const removeOrder = (id) => {
    dispatch({
      type: "ADMIN_REMOVE_ORDER",
      payload: id,
    });
  };

  return (
    <GlobalContext.Provider
      value={{
        branches: state.branches,
        orders: state.orders,
        initBranches,
        addBranch,
        editBranch,
        removeBranch,
        initOrders,
        addOrder,
        editOrder,
        removeOrder,
        submitOrder
      }}
    >
      <AuthContext.Provider value={[auth, setAuth]}>
        {children}
      </AuthContext.Provider>
    </GlobalContext.Provider>
  );
};