import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Input,
  Col,
  FormText,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";

const FileAddModal = ({
  setModalVisible,
  visible,
  currentItem,
  setCurrentItem,
  setItem,
  url,
  title
}) => {
  const [fileName, setFileName] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const handleCancel = () => {
    setFileName(null);
    setSelectedFile(null);
    setModalVisible(false);
    setCurrentItem(null);
  };

  const handleSubmit = () => {
    setErrorMessage(null);
    const formData = new FormData();
    formData.append("formFile", selectedFile, fileName);
    axios
      .post(url + currentItem.id, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        setItem({
          ...response.data,
        });
        setFileName(null);
        setSelectedFile(null);
        setCurrentItem(null);
        setModalVisible(false);
      })
      .catch((error) => {
        if (error.message === "Network Error")
          setErrorMessage("Plik jest zbyt duży.");
        else setErrorMessage(error.response.data.message);
      });
  };

  if (!visible) return null;
  if (currentItem == null) return null;

  return (
    <div>
      <Modal isOpen={visible}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Col className="mt-3">
                <Input
                  name="file"
                  type="file"
                  onChange={(event) => {
                    setSelectedFile(event.target.files[0]);
                    setFileName(event.target.files[0].name);
                  }}
                />
                <FormText>
                  Proszę załączyć plik z {title}.
                </FormText>
              </Col>
            </FormGroup>
            {errorMessage && (
              <FormGroup>
                <span style={{ color: "red" }}>{errorMessage}</span>
              </FormGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={handleCancel}>
            Anuluj
          </Button>
          <Button onClick={handleSubmit}>Zapisz</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FileAddModal;
