import React, { useState, useContext } from "react";
import { Button, Form, FormGroup, Label, Input, Col } from "reactstrap";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import * as yup from "yup";
import YupPassword from "yup-password";
import { yupResolver } from "@hookform/resolvers/yup";
import { useForm, Controller } from "react-hook-form";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { AuthContext } from "../context/GlobalState";

YupPassword(yup);

const formSchema = yup
  .object()
  .shape({
    oldPassword: yup
      .string()
      .required("Stare hasło jest wymagane")
      .min(6, "Hasło musi mieć przynajmniej 6 znaków"),
    newPassword: yup
      .string()
      .required("Nowe hasło jest wymagane")
      .min(6, "Hasło musi mieć przynajmniej 6 znaków")
      .minLowercase(1, "Hasło musi zawierać przynajmniej 1 małą literę")
      .minUppercase(1, "Hasło musi zawierać przynajmniej 1 dużą literę")
      .minNumbers(1, "Hasło musi zawierać przynajmniej 1 cyfrę")
      .minSymbols(1, "Hasło musi zawierać przynajmniej 1 znak specjalny"),
    confirmPassword: yup
      .string()
      .required("Potwierdzenie nowego hasła jest wymagane")
      .min(6, "Hasło musi mieć przynajmniej 6 znaków")
      .minLowercase(1, "Hasło musi zawierać przynajmniej 1 małą literę")
      .minUppercase(1, "Hasło musi zawierać przynajmniej 1 dużą literę")
      .minNumbers(1, "Hasło musi zawierać przynajmniej 1 cyfrę")
      .minSymbols(1, "Hasło musi zawierać przynajmniej 1 znak specjalny")
      .oneOf([yup.ref("newPassword")], "Hasła nie są jednakowe"),
  })
  .required();

const ResetPassword = () => {
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState(null);
  const [, setAuth] = useContext(AuthContext);

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      oldPassword: "",
      newPassword: "",
      confirmPassword: "",
    },
    reValidateMode: "onChange",
    resolver: yupResolver(formSchema),
  });

  const onSubmit = (data) => {
    console.log(data);
    setErrorMessage(null);
    axios
      .post("/api/account/change-password", data)
      .then((response) => {
        setAuth({ authorized: true, ...response.data });
        navigate("/");
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <div>
      <h3>
        Ustawienia hasła
        <p
          style={{
            fontSize: 15,
            color: "#403F6F",
            textTransform: "none",
          }}
          className="mb-0"
        >
          <HiOutlineArrowLongRight size={24} /> Zmień hasło
        </p>
      </h3>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Label sm={3}>Stare hasło</Label>
          <Col sm={5}>
            <Controller
              id="oldPassword"
              name="oldPassword"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="password"
                  className={`form-control ${
                    errors.oldPassword ? "is-invalid" : ""
                  }`}
                />
              )}
            />
            <div className="invalid-feedback">
              {errors.oldPassword?.message}
            </div>
          </Col>
          <Label sm={3}>Nowe hasło</Label>
          <Col sm={5}>
            <Controller
              id="newPassword"
              name="newPassword"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="password"
                  className={`form-control ${
                    errors.newPassword ? "is-invalid" : ""
                  }`}
                />
              )}
            />
            <div className="invalid-feedback">
              {errors.newPassword?.message}
            </div>
          </Col>
          <Label sm={3}>Potwierdź nowe hasło</Label>
          <Col sm={5}>
            <Controller
              id="confirmPassword"
              name="confirmPassword"
              control={control}
              render={({ field }) => (
                <Input
                  {...field}
                  type="password"
                  className={`form-control ${
                    errors.confirmPassword ? "is-invalid" : ""
                  }`}
                />
              )}
            />
            <div className="invalid-feedback">
              {errors.confirmPassword?.message}
            </div>
          </Col>
        </FormGroup>
        {errorMessage && (
          <FormGroup>
            <span style={{ color: "red" }}>{errorMessage}</span>
          </FormGroup>
        )}
        <Button type="submit">Ustaw nowe hasło</Button>
      </Form>
    </div>
  );
};

export default ResetPassword;
