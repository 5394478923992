import React, { useState } from "react";
import { Button, FormGroup, Modal, ModalBody, ModalFooter } from "reactstrap";
import axios from "axios";

const FileRemoveModal = ({
  setModalVisible,
  visible,
  currentItem,
  setCurrentItem,
  setItem,
  url,
  title,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);

  const handleCancel = () => {
    setErrorMessage(null);
    setModalVisible(false);
  };

  const handleSubmit = () => {
    setErrorMessage(null);
    axios
      .post(url, {
        id: currentItem.id,
      })
      .then((response) => {
        setItem({ ...currentItem, fileName: null, fileContentType: null });
        setModalVisible(false);
        setCurrentItem(null);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  if (!visible) return null;

  return (
    <div>
      <Modal isOpen={visible}>
        <ModalBody>
          Czy na pewno chcesz usunąć <b>{title}</b>?
          {errorMessage && (
            <FormGroup>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={handleCancel}>
            Anuluj
          </Button>
          <Button onClick={handleSubmit}>Usuń</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FileRemoveModal;
