import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Button, FormGroup } from "reactstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import axios from "axios";

import { GlobalContext } from "../context/GlobalState";
import { getOrderStatusLabel } from "../models/OrderStatusType";
import FileViewModal from "../global/FileViewModal";
import FileAddModal from "../global/FileAddModal";
import FileRemoveModal from "../global/FileRemoveModal";

const OrderList = () => {
  const navigate = useNavigate();
  const { orders, initOrders, addOrder, editOrder } = useContext(GlobalContext);
  const [errorMessage, setErrorMessage] = useState(null);
  const [fileViewModalVisible, setFileViewModalVisible] = useState(false);
  const [fileAddModalVisible, setFileAddModalVisible] = useState(false);
  const [fileRemoveModalVisible, setFileRemoveModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);

  const openGetItemFileModal = (item) => {
    setCurrentItem(item);
    setFileViewModalVisible(true);
  };

  const openAddItemFileModal = (item) => {
    setCurrentItem(item);
    setFileAddModalVisible(true);
  };

  const openRemoveItemFileModal = (item) => {
    setCurrentItem(item);
    setFileRemoveModalVisible(true);
  };

  useEffect(() => {
    axios
      .get("api/orders")
      .then((response) => {
        initOrders(response.data);
      })
      .catch((error) => {
        setErrorMessage("Coś poszło nie tak. Przeładuj stronę.");
      });
  }, []);

  if (orders === null && errorMessage === null) return null;
  if (orders === null && errorMessage !== null)
    return (
      <FormGroup>
        <span style={{ color: "red" }}>{errorMessage}</span>
      </FormGroup>
    );

  const handleAddOrder = () => {
    axios
      .post("api/orders")
      .then((response) => {
        addOrder(response.data);
        navigate(`/zamowienia/${response.data.id}`);
      })
      .catch((error) => setErrorMessage(error.response.data.message));
  };
  return (
    <div>
      <table className="table table-striped">
        <thead className="table-light">
          <tr>
            <th>Nr</th>
            <th>Oddział</th>
            <th>Status</th>
            <th>Protokół</th>
            <th style={{ textAlign: "center" }}>
              <HiDotsHorizontal />
            </th>
          </tr>
        </thead>
        <tbody>
          {orders &&
            orders.length > 0 &&
            orders.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.branchName}</td>
                  <td>{getOrderStatusLabel(item.status)}</td>
                  <td style={{ width: "125px" }}>
                    {item.fileName === null ||
                    typeof item.fileName === "undefined" ? (
                      <Button onClick={() => openAddItemFileModal(item)}>
                        Dodaj plik
                      </Button>
                    ) : (
                      <div className="d-flex align-items-center">
                        <Button
                          style={{ marginRight: "5px" }}
                          onClick={() => openGetItemFileModal(item)}
                        >
                          Podgląd
                        </Button>
                        <Button onClick={() => openRemoveItemFileModal(item)}>
                          Usuń
                        </Button>
                      </div>
                    )}
                  </td>
                  <td style={{ textAlign: "center" }}>
                    <Link to={`/zamowienia/${item.id}`} state={{ item }}>
                      <Button style={{ margin: 0 }}>Szczegóły</Button>
                    </Link>
                  </td>
                </tr>
              );
            })}
        </tbody>
      </table>
      {(!orders || orders.length === 0) && "Tu jeszcze nic nie ma"}
      {errorMessage && (
        <FormGroup>
          <span style={{ color: "red" }}>{errorMessage}</span>
        </FormGroup>
      )}
      <div>
        <Button onClick={handleAddOrder}>Dodaj zamówienie</Button>
      </div>
      <FileAddModal
        setModalVisible={setFileAddModalVisible}
        visible={fileAddModalVisible}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        setItem={editOrder}
        url={"api/orders/save-file/"}
        title={"protokołem zdawczo-odbiorczym"}
      />
      <FileViewModal
        setModalVisible={setFileViewModalVisible}
        visible={fileViewModalVisible}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        url={"api/orders/get-file"}
      />
      <FileRemoveModal
        setModalVisible={setFileRemoveModalVisible}
        visible={fileRemoveModalVisible}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        setItem={editOrder}
        url={"api/orders/delete-file"}
        title={"protokół zdawczo-odbiorczy"}
      />
    </div>
  );
};

export default OrderList;
