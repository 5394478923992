import React, { useEffect, useState } from "react";
import { Button, FormGroup } from "reactstrap";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

import FileViewModal from "../global/FileViewModal";
import OrderItemInventory from "../global/OrderItemInventory";

const AdminOrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [order, setOrder] = useState({ items: [] });
  const [errorMessage, setErrorMessage] = useState(null);
  const [currentItem, setCurrentItem] = useState(null);
  const [getItemFileModalVisible, setGetItemFileModalVisible] = useState(false);

  useEffect(() => {
    axios
      .get(`/api/admin/orders/${id}`)
      .then((response) => setOrder(response.data))
      .catch((error) =>
        setErrorMessage("Coś poszło nie tak. Przeładuj stronę.")
      );
  }, [id]);

  const openGetItemFileModal = (item) => {
    setCurrentItem(item);
    setGetItemFileModalVisible(true);
  };

  if (order === null && errorMessage !== null)
    return (
      <FormGroup>
        <span style={{ color: "red" }}>{errorMessage}</span>
      </FormGroup>
    );

  return (
    <div>
      <div
        style={{ height: "40px" }}
        className="d-flex justify-content-between mb-3"
      >
        <BsArrowLeftCircleFill
          className="btn-arrow"
          onClick={() => navigate(-1)}
        />
      </div>
      <table className="table table-striped">
        <thead className="table-light">
          <tr>
            <th>Imię i nazwisko</th>
            <th>Zamawiany asortyment</th>
            <th>Karta pomiaru</th>
          </tr>
        </thead>
        <tbody>
          {order.items &&
            order.items.length > 0 &&
            order.items.map((item) => (
              <tr key={item.id}>
                <td>{item.fullName}</td>
                <td>
                  <OrderItemInventory inventory={item}></OrderItemInventory>
                </td>
                <td>
                  {item.fileName === null ||
                  typeof item.fileName === "undefined" ? (
                    "Brak załącznika"
                  ) : (
                    <Button
                      style={{ marginRight: "5px" }}
                      onClick={() => openGetItemFileModal(item)}
                    >
                      Podgląd
                    </Button>
                  )}
                </td>
              </tr>
            ))}
        </tbody>
      </table>
      <FileViewModal
        setModalVisible={setGetItemFileModalVisible}
        visible={getItemFileModalVisible}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        url={"/api/admin/orders/get-order-item-file"}
      />
    </div>
  );
};

export default AdminOrderDetails;
