import React, { useEffect, useState } from "react";
import {
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { saveAs } from "file-saver";
import axios from "axios";
import { Document, Page } from "react-pdf";

import { BsArrowLeftCircleFill, BsArrowRightCircleFill } from "react-icons/bs";

const FileViewModal = ({
  setModalVisible,
  visible,
  currentItem,
  setCurrentItem,
  url,
}) => {
  const [currentFile, setCurrentFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [numPages, setNumPages] = useState(null);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    if (
      visible === true &&
      currentItem !== null &&
      typeof currentItem !== "undefined"
    ) {
      axios
        .post(
          url,
          { id: currentItem.id },
          {
            responseType: "blob",
          }
        )
        .then((response) => {
          setCurrentFile(response.data);
        })
        .catch((error) => {
          setErrorMessage(error.response.data.message);
        });
    }
  }, [visible, currentItem, url]);

  const handleCancel = () => {
    setModalVisible(false);
    setCurrentItem(null);
    setCurrentFile(null);
  };

  const handleDownload = () => {
    saveAs(currentFile, currentItem.fileName);
  };

  function onDocumentLoadSuccess({ numPages }) {
    setNumPages(numPages);
    setPageNumber(1);
  }

  function changePage(offset) {
    setPageNumber((prevPageNumber) => prevPageNumber + offset);
  }

  function previousPage() {
    changePage(-1);
  }

  function nextPage() {
    changePage(1);
  }

  if (!visible) return null;
  if (currentItem == null) return null;

  return (
    <div>
      <Modal size="lg" isOpen={visible}>
        <ModalHeader>Podgląd</ModalHeader>
        <ModalBody>
          {currentFile &&
            (currentItem.fileContentType === "image/jpeg" ||
              currentItem.fileContentType === "image/png") && (
              <img
                className="card-img"
                alt="pomiary"
                width={"250px"}
                src={URL.createObjectURL(currentFile)}
              />
            )}
          {currentFile && currentItem.fileContentType === "application/pdf" && (
            <Document
              options={{ workerSrc: "pdf.worker.js" }}
              file={currentFile}
              onLoadSuccess={onDocumentLoadSuccess}
            >
              <Page
                renderAnnotationLayer={false}
                renderTextLayer={false}
                pageNumber={pageNumber}
              />

              {numPages > 1 && (
                <div className="d-flex flex-column justify-content-center">
                  <p className="text-center mb-1">
                    Strona {pageNumber || (numPages ? 1 : "--")} z{" "}
                    {numPages || "--"}
                  </p>
                  <div className="d-flex justify-content-center">
                    <Button
                      className="rounded-pill mx-1"
                      type="button"
                      style={{
                        background: "none",
                        padding: "0",
                        borderWidth: "0",
                      }}
                      disabled={pageNumber <= 1}
                    >
                      <BsArrowLeftCircleFill
                        className="btn-arrow"
                        size={20}
                        onClick={previousPage}
                      />
                    </Button>
                    <Button
                      className="rounded-pill mx-1"
                      type="button"
                      style={{
                        background: "none",
                        padding: "0",
                        borderWidth: "0",
                      }}
                      disabled={pageNumber >= numPages}
                    >
                      <BsArrowRightCircleFill
                        className="btn-arrow"
                        size={20}
                        onClick={nextPage}
                      />
                    </Button>
                  </div>
                </div>
              )}
            </Document>
          )}
          {errorMessage && (
            <FormGroup>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={handleCancel}>
            Zamknij
          </Button>
          {typeof currentFile !== "undefined" && currentFile !== null && (
            <Button onClick={handleDownload}>Pobierz</Button>
          )}
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default FileViewModal;
