import React, { useState, useContext, useEffect } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { useNavigate, useParams } from "react-router-dom";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import axios from "axios";
import { GlobalContext } from "../context/GlobalState";

const AdminBranchEdit = (props) => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [branch, setBranch] = useState(null);
  const { branches, editBranch } = useContext(GlobalContext);
  const [errorMessage, setErrorMessage] = useState(null);

  useEffect(() => {
    setBranch(branches.find((x) => x.id === Number(id)));
  }, [id, branches]);

  const handleSubmit = () => {
    setErrorMessage(null);
    axios
      .post("/api/admin/branches/edit", branch)
      .then((response) => {
        editBranch(branch);
        navigate("/admin/oddzialy");
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setBranch({ ...branch, [name]: value });
  };

  if(branch === null)
    return null;

  return (
    <div>
      <BsArrowLeftCircleFill
        className="btn-arrow"
        onClick={() => navigate(-1)}
      />
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Oddział</Label>
          <Input
            name="name"
            value={branch.name}
            type="text"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Kod pocztowy</Label>
          <Input
            name="postCode"
            value={branch.postCode}
            type="text"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Miasto</Label>
          <Input
            name="city"
            value={branch.city}
            type="text"
            onChange={handleChange}
          />
        </FormGroup>
        <FormGroup>
          <Label>Ulica i numer</Label>
          <Input
            name="address"
            value={branch.address}
            type="text"
            onChange={handleChange}
          />
        </FormGroup>
        {errorMessage && (
          <FormGroup>
            <span style={{ color: "red" }}>{errorMessage}</span>
          </FormGroup>
        )}
        <Button onClick={(event) => handleSubmit(event)}>Zapisz</Button>
      </Form>
    </div>
  );
};

export default AdminBranchEdit;
