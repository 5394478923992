import React, { useState, useContext } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";
import { Link } from "react-router-dom";

import Logo from "../../assets/polanex_logo.png";
import { AuthContext } from "../context/GlobalState";

const Login = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [, setAuth] = useContext(AuthContext);

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage(null);
    axios
      .post("/api/account/login", { email, password })
      .then((response) => {
        setAuth({ authorized: true, ...response.data });
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <div className="d-flex flex-column min-vh-100 justify-content-center align-items-center">
      <Card style={{ width: "400px" }}>
        <img src={Logo} alt="Logo" />
        <CardBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup className="d-flex flex-column  align-items-center">
              <Col sm={11}>
                <Label sm={3} for="email">
                  Email
                </Label>
              </Col>
              <Col sm={11}>
                <Input
                  name="email"
                  type="text"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
              </Col>
              <Col sm={11}>
                <Label sm={3} for="password">
                  Hasło
                </Label>
              </Col>
              <Col sm={11}>
                <Input
                  name="password"
                  type="password"
                  value={password}
                  onChange={(e) => setPassword(e.target.value)}
                />
              </Col>

              <Link
                className="w-100 ps-3 text-left link"
                role="button"
                to="/zapomnialem-hasla"
              >
                Nie pamiętasz hasła?
              </Link>
            </FormGroup>
            <div className="d-flex flex-column  align-items-center mb-5">
              {errorMessage && (
                <FormGroup>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                </FormGroup>
              )}
              <Col sm={11}>
                <Button className="w-100">Zaloguj</Button>
              </Col>
            </div>
          </Form>
        </CardBody>
      </Card>
    </div>
  );
};

export default Login;
