import StatusType from "../models/OrderStatusType";

const AppReducer = (state, action) => {
  switch (action.type) {
    case "INIT_BRANCHES":
      return {
        ...state,
        branches: action.payload,
      };
    case "ADD_BRANCH":
      return {
        ...state,
        branches: [action.payload, ...state.branches],
      };
    case "EDIT_BRANCH":
      return {
        ...state,
        branches: state.branches.map((branch) =>
          branch.id === action.payload.id ? action.payload : branch
        ),
      };
    case "REMOVE_BRANCH":
      return {
        ...state,
        branches: state.branches.filter((branch) => {
          return branch.id !== action.payload;
        }),
      };
      case "INIT_ORDERS":
        return {
          ...state,
          orders: action.payload,
        };
      case "ADD_ORDER":
        return {
          ...state,
          orders: [action.payload, ...state.orders],
        };
      case "EDIT_ORDER":
        return {
          ...state,
          orders: state.orders.map((order) =>
            order.id === action.payload.id ? action.payload : order
          ),
        };
        case "SUBMIT_ORDER":
          return {
            ...state,
            orders: state.orders.map((order) =>
              order.id === action.payload.id 
              ? {...order, status: StatusType.SUBMITTED} 
              : order
            ),
          };
      case "REMOVE_ORDER":
        return {
          ...state,
          orders: state.orders.filter((order) => {
            return order.id !== action.payload;
          }),
        };
    default:
      return state;
  }
};

export default AppReducer;
