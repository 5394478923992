import React, { useContext, useEffect, useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Button, FormGroup, Modal, ModalBody, ModalFooter } from "reactstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { MdDeleteOutline } from "react-icons/md";
import axios from "axios";

import { AuthContext, GlobalContext } from "../context/GlobalState";
import OrderStatusType, {
  getOrderStatusLabel,
} from "../models/OrderStatusType";
import FileAddModal from "../global/FileAddModal";
import FileViewModal from "../global/FileViewModal";
import FileRemoveModal from "../global/FileRemoveModal";

const AdminOrderList = () => {
  const [auth] = useContext(AuthContext);
  const { id } = useParams();
  const { branches } = useContext(GlobalContext);
  const [branch, setBranch] = useState(null);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [orders, setOrders] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [addItemFileModalVisible, setAddItemFileModalVisible] = useState(false);
  const [getItemFileModalVisible, setGetItemFileModalVisible] = useState(false);
  const [removeItemFileModalVisible, setRemoveItemFileModalVisible] =
    useState(false);
  const [
    getItemComplaintFileModalVisible,
    setGetItemComplaintFileModalVisible,
  ] = useState(false);

  const [changeStatusModalVisible, setChangeStatusModalVisible] =
    useState(false);

  const openAddItemFileModal = (item) => {
    setCurrentItem(item);
    setAddItemFileModalVisible(true);
  };

  const openGetItemFileModal = (item) => {
    setCurrentItem(item);
    setGetItemFileModalVisible(true);
  };

  const openGetItemComplaintFileModal = (item) => {
    setCurrentItem(item);
    setGetItemComplaintFileModalVisible(true);
  };

  const openRemoveItemFileModal = (item) => {
    setCurrentItem(item);
    setRemoveItemFileModalVisible(true);
  };

  const setOrder = (order) => {
    setOrders((previous) =>
      previous.map((x) => (x.id === order.id ? order : x))
    );
  };

  useEffect(() => {
    setBranch(branches.find((x) => x.id === Number(id)));
  }, [id, branches]);

  useEffect(() => {
    if (id === null || typeof id === "undefined" || id === "") {
      axios
        .get(`/api/admin/orders`)
        .then((response) => {
          setOrders(response.data);
        })
        .catch((error) => {
          setErrorMessage("Coś poszło nie tak. Przeładuj stronę.");
        });
    } else {
      axios
        .get(`/api/admin/orders/branch/${id}`)
        .then((response) => {
          setOrders(response.data);
        })
        .catch((error) => {
          setErrorMessage("Coś poszło nie tak. Przeładuj stronę.");
        });
    }
  }, [id]);

  const handleRemoveModal = (item) => {
    setCurrentItem(item);
    setRemoveModalVisible(true);
  };

  const handleRemoveOrder = () => {
    setErrorMessage(null);
    axios
      .post("/api/admin/orders/delete", { id: currentItem.id })
      .then((response) => {
        setOrders(
          orders.filter((order) => {
            return order.id !== currentItem.id;
          })
        );
        setRemoveModalVisible(false);
        setCurrentItem(null);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  const cancelRemoveOrder = () => {
    setRemoveModalVisible(false);
    setErrorMessage(null);
    setCurrentItem(null);
  };

  const openChangeStatusModal = (id, status) => {
    setCurrentItem({ id, status });
    setChangeStatusModalVisible(true);
  };

  const changeStatus = () => {
    setErrorMessage(null);
    axios
      .post("/api/admin/orders/change-status", currentItem)
      .then((response) => {
        setCurrentItem(null);
        setOrder(response.data);
        setChangeStatusModalVisible(false);
      })
      .catch((error) => setErrorMessage(error.response.data.message));
  };

  const cancelChangeStatus = () => {
    setErrorMessage(null);
    setCurrentItem(null);
    setChangeStatusModalVisible(false);
  };

  if (orders === null && errorMessage === null) return null;
  if (orders === null && errorMessage !== null)
    return (
      <FormGroup>
        <span style={{ color: "red" }}>{errorMessage}</span>
      </FormGroup>
    );

  return (
    <div>
      <h3>
        {typeof branch !== "undefined" && branch !== null
          ? branch.name
          : "Wszystkie zamówienia"}
        <p
          style={{
            fontSize: 15,
            color: "#403F6F",
            textTransform: "none",
          }}
          className="mb-0"
        >
          <HiOutlineArrowLongRight size={24} /> zarządzaj zamówieniami
        </p>
      </h3>
      <table className="table table-striped">
        <thead className="table-light">
          <tr>
            <th>Nr</th>
            <th>Oddział</th>
            <th>Status</th>
            <th>Protokół</th>
            <th>Reklamacja</th>
            <th style={{ textAlign: "right" }}>
              <HiDotsHorizontal />
            </th>
            {auth?.isAdmin && <th style={{ textAlign: "right" }}>Admin</th>}
          </tr>
        </thead>
        <tbody>
          {orders &&
            orders.length > 0 &&
            orders.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.id}</td>
                  <td>{item.branchName}</td>
                  <td>
                    <span style={{ marginRight: "10px" }}>
                      {getOrderStatusLabel(item.status)}
                    </span>
                    {auth?.isAdmin && (
                      <>
                        {item.status === OrderStatusType.SUBMITTED && (
                          <Button
                            style={{ marginRight: "5px" }}
                            onClick={() =>
                              openChangeStatusModal(
                                item.id,
                                OrderStatusType.IN_PROGRESS
                              )
                            }
                          >
                            {getOrderStatusLabel(OrderStatusType.IN_PROGRESS)}
                          </Button>
                        )}
                        {item.status === OrderStatusType.IN_PROGRESS && (
                          <Button
                            style={{ marginRight: "5px" }}
                            onClick={() =>
                              openChangeStatusModal(
                                item.id,
                                OrderStatusType.SENT
                              )
                            }
                          >
                            {getOrderStatusLabel(OrderStatusType.SENT)}
                          </Button>
                        )}
                        {item.status === OrderStatusType.SENT && (
                          <Button
                            style={{ marginRight: "5px" }}
                            onClick={() =>
                              openChangeStatusModal(
                                item.id,
                                OrderStatusType.FINISHED
                              )
                            }
                          >
                            {getOrderStatusLabel(OrderStatusType.FINISHED)}
                          </Button>
                        )}
                        {item.status ===
                          OrderStatusType.COMPLAINT_ACCEPTED_INPROGRESS && (
                          <Button
                            style={{ marginRight: "5px" }}
                            onClick={() =>
                              openChangeStatusModal(
                                item.id,
                                OrderStatusType.COMPLAINT_ACCEPTED_SENT
                              )
                            }
                          >
                            {getOrderStatusLabel(
                              OrderStatusType.COMPLAINT_ACCEPTED_SENT
                            )}
                          </Button>
                        )}
                        {item.status ===
                          OrderStatusType.COMPLAINT_ACCEPTED_SENT && (
                          <Button
                            style={{ marginRight: "5px" }}
                            onClick={() =>
                              openChangeStatusModal(
                                item.id,
                                OrderStatusType.FINISHED
                              )
                            }
                          >
                            {getOrderStatusLabel(OrderStatusType.FINISHED)}
                          </Button>
                        )}
                      </>
                    )}
                  </td>
                  <td style={{ width: "125px" }}>
                    {item.fileName === null ||
                    typeof item.fileName === "undefined" ? (
                      auth?.isAdmin && (
                        <Button onClick={() => openAddItemFileModal(item)}>
                          Dodaj plik
                        </Button>
                      )
                    ) : (
                      <div className="d-flex align-items-center">
                        <Button
                          style={{ marginRight: "5px" }}
                          onClick={() => openGetItemFileModal(item)}
                        >
                          Podgląd
                        </Button>
                        {auth?.isAdmin && (
                          <Button onClick={() => openRemoveItemFileModal(item)}>
                            Usuń
                          </Button>
                        )}
                      </div>
                    )}
                  </td>
                  <td>
                    {(item.complaintFileName === null ||
                      typeof item.complaintFileName === "undefined") && (
                      <>Brak</>
                    )}
                    {item.complaintFileName !== null &&
                      typeof item.complaintFileName !== "undefined" && (
                        <div className="d-flex align-items-center">
                          <Button
                            style={{ marginRight: "5px" }}
                            onClick={() => openGetItemComplaintFileModal(item)}
                          >
                            Podgląd
                          </Button>
                          {auth?.isAdmin &&
                            item.status ===
                              OrderStatusType.COMPLAINT_ISSUED && (
                              <>
                                <Button
                                  style={{ marginRight: "5px" }}
                                  onClick={() =>
                                    openChangeStatusModal(
                                      item.id,
                                      OrderStatusType.COMPLAINT_ACCEPTED_INPROGRESS
                                    )
                                  }
                                >
                                  Akceptuj
                                </Button>
                                <Button
                                  style={{ marginRight: "5px" }}
                                  onClick={() =>
                                    openChangeStatusModal(
                                      item.id,
                                      OrderStatusType.COMPLAINT_REJECTED
                                    )
                                  }
                                >
                                  Odrzuć
                                </Button>
                              </>
                            )}
                        </div>
                      )}
                  </td>
                  <td style={{ textAlign: "right" }}>
                    <Link
                      style={{ marginRight: "5px" }}
                      to={`/admin/zamowienia/${item.id}`}
                      state={{ item }}
                    >
                      <Button style={{ margin: 0 }}>Szczegóły</Button>
                    </Link>
                  </td>
                  {auth?.isAdmin && (
                    <td style={{ textAlign: "right" }}>
                      {/* <Link
                        to={`/admin/zamowienia/edytuj/${item.id}`}
                        state={{ item }}
                      >
                        <FaEdit
                          style={{ paddingTop: "5px", width: "23px" }}
                          className="table-icon"
                        />
                      </Link> */}
                      <MdDeleteOutline
                        className="table-icon"
                        onClick={() => handleRemoveModal(item)}
                        style={{ margin: 0 }}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
      {(!orders || orders.length === 0) && "Tu jeszcze nic nie ma"}
      <Modal isOpen={removeModalVisible}>
        <ModalBody>
          {currentItem !== null && (
            <>
              Czy na pewno chcesz usunąć zamówienie <b>{currentItem.name}</b>?
              {errorMessage && (
                <FormGroup>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                </FormGroup>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={cancelRemoveOrder}>
            Anuluj
          </Button>
          <Button onClick={handleRemoveOrder}>Akceptuj</Button>
        </ModalFooter>
      </Modal>
      <FileAddModal
        setModalVisible={setAddItemFileModalVisible}
        visible={addItemFileModalVisible}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        setItem={setOrder}
        url={"api/admin/orders/save-file/"}
        title={"protokołem zdawczo-odbiorczym"}
      />
      <FileViewModal
        setModalVisible={setGetItemFileModalVisible}
        visible={getItemFileModalVisible}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        url={"api/admin/orders/get-file"}
      />
      <FileRemoveModal
        setModalVisible={setRemoveItemFileModalVisible}
        visible={removeItemFileModalVisible}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        setItem={setOrder}
        url={"api/admin/orders/delete-file"}
        title={"protokół zdawczo-odbiorczy"}
      />
      <FileViewModal
        setModalVisible={setGetItemComplaintFileModalVisible}
        visible={getItemComplaintFileModalVisible}
        currentItem={currentItem}
        setCurrentItem={setCurrentItem}
        url={"api/admin/orders/get-complaint-file"}
      />
      <Modal isOpen={changeStatusModalVisible}>
        <ModalBody>
          Czy chcesz zmienić status zamówienia {currentItem?.id} na{" "}
          <b>{getOrderStatusLabel(currentItem?.status)}</b>?<br></br>
          {errorMessage && (
            <FormGroup>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={cancelChangeStatus}>
            Anuluj
          </Button>
          <Button onClick={changeStatus}>Zatwierdź</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default AdminOrderList;
