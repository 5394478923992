import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { Button, FormGroup, Modal, ModalBody, ModalFooter } from "reactstrap";
import { HiDotsHorizontal } from "react-icons/hi";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { MdAdd, MdFeedback } from "react-icons/md";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { MdDeleteOutline } from "react-icons/md";
import axios from "axios";

import { GlobalContext } from "../context/GlobalState";

import OrderItemAddModal from "./OrderItemAddModal";
import OrderItemRemoveModal from "./OrderItemRemoveModal";

import OrderItemInventoryModal from "./OrderItemInventoryModal";
import OrderItemInventory from "../global/OrderItemInventory";

import FileAddModal from "../global/FileAddModal";
import FileViewModal from "../global/FileViewModal";
import FileRemoveModal from "../global/FileRemoveModal";

import OrderStatusType, {
  getOrderStatusLabel,
} from "../models/OrderStatusType";

const OrderDetails = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [order, setOrder] = useState({ items: [] });
  const [addItemModalVisible, setAddItemModalVisible] = useState(false);
  const [itemInventoryModalVisible, setItemInventoryModalVisible] =
    useState(false);
  const [addComplaintFileModalVisible, setAddComplaintFileModalVisible] = useState(false);
  const [getComplaintFileModalVisible, setGetComplaintFileModalVisible] = useState(false);
  const [addItemFileModalVisible, setAddItemFileModalVisible] = useState(false);
  const [getItemFileModalVisible, setGetItemFileModalVisible] = useState(false);
  const [removeItemFileModalVisible, setRemoveItemFileModalVisible] =
    useState(false);
  const [submitModalVisible, setSubmitModalVisible] = useState(false);
  const [removeItemModalVisible, setRemoveItemModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);

  const { submitOrder } = useContext(GlobalContext);

  useEffect(() => {
    axios
      .get(`/api/orders/${id}`)
      .then((response) => setOrder(response.data))
      .catch((error) =>
        setErrorMessage("Coś poszło nie tak. Przeładuj stronę.")
      );
  }, [id]);

  const addOrderItem = (orderItem) => {
    setOrder({ ...order, items: [...order.items, orderItem] });
  };

  const setOrderItem = (orderItem) => {
    setOrder({
      ...order,
      items: order.items.map((item) =>
        item.id === orderItem.id ? orderItem : item
      ),
    });
  };

  const removeOrderItem = (id) => {
    setOrder((previous) => {
      const index = previous.items.findIndex((x) => x.id === id);
      if (index > -1) {
        previous.items.splice(index, 1);
        return {
          ...previous,
          items: [...previous.items],
        };
      }
      return previous;
    });
  };

  const handleSubmitOrder = () => {
    setErrorMessage(null);
    axios
      .post("/api/orders/submit", { id })
      .then((response) => {
        submitOrder(id);
        setErrorMessage(null);
        setSubmitModalVisible(false);
        navigate("/zamowienia");
      })
      .catch((error) => setErrorMessage(error.response.data.message));
  };

  const openAddItemModal = () => {
    setAddItemModalVisible(true);
  };

  const openAddComplaintFileModal = () => {
    setCurrentItem(order);
    setAddComplaintFileModalVisible(true);
  };

  const openGetComplaintFileModal = () => {
    setCurrentItem(order);
    setGetComplaintFileModalVisible(true);
  };

  const openEditItemInventoryModal = (item) => {
    setCurrentItem(item);
    setItemInventoryModalVisible(true);
  };

  const openAddItemFileModal = (item) => {
    setCurrentItem(item);
    setAddItemFileModalVisible(true);
  };

  const openGetItemFileModal = (item) => {
    setCurrentItem(item);
    setGetItemFileModalVisible(true);
  };

  const openRemoveItemModal = (item) => {
    setCurrentItem(item);
    setRemoveItemModalVisible(true);
  };

  const openRemoveItemFileModal = (item) => {
    setCurrentItem(item);
    setRemoveItemFileModalVisible(true);
  };

  const cancelSubmitOrder = () => {
    setSubmitModalVisible(false);
    setErrorMessage(null);
  };

  if (order === null && errorMessage === null) return null;
  if (order === null && errorMessage !== null)
    return (
      <FormGroup>
        <span style={{ color: "red" }}>{errorMessage}</span>
      </FormGroup>
    );

  return (
    <div>
      <h3>
        {typeof order?.id !== "undefined" &&
          order?.id !== null &&
          `Zamówienie nr ${order.id}`}
        {typeof order?.status !== "undefined" && order?.status !== null && (
          <p
            style={{
              fontSize: 15,
              color: "#403F6F",
              textTransform: "none",
            }}
            className="mb-0"
          >
            <HiOutlineArrowLongRight size={24} /> <b>Status zamówienia:</b>{" "}
            {getOrderStatusLabel(order.status)}
          </p>
        )}
      </h3>
      <div
        style={{ height: "40px" }}
        className="d-flex justify-content-between mb-3"
      >
        <BsArrowLeftCircleFill
          className="btn-arrow"
          onClick={() => navigate(-1)}
        />
        {order.status === 0 && (
          <Button onClick={openAddItemModal}>
            <MdAdd className="btn-icon" />
            Dodaj pracownika
          </Button>
        )}
        {(order.status === OrderStatusType.COMPLAINT_ISSUED || 
        order.status === OrderStatusType.COMPLAINT_ACCEPTED_INPROGRESS ||
        order.status === OrderStatusType.COMPLAINT_ACCEPTED_SENT ||
        order.status === OrderStatusType.COMPLAINT_REJECTED) && (
          <Button onClick={openGetComplaintFileModal}>
            <MdFeedback className="btn-icon" />
            Podgląd reklamacji
          </Button>
        )}
        {(order.status === OrderStatusType.SENT || 
        order.status === OrderStatusType.FINISHED ||
        order.status === OrderStatusType.COMPLAINT_REJECTED) && (
          <Button onClick={openAddComplaintFileModal}>
            <MdFeedback className="btn-icon" />
            Złóż reklamację
          </Button>
        )}
      </div>
      <table className="table table-striped">
        <thead className="table-light">
          <tr>
            <th>Imię i nazwisko</th>
            <th>Zamawiany asortyment</th>
            <th>Karta pomiaru</th>
            {order.status === 0 && (
              <th style={{ textAlign: "center" }}>
                <HiDotsHorizontal />
              </th>
            )}
          </tr>
        </thead>
        <tbody>
          {order.items &&
            order.items.length > 0 &&
            order.items.map((item) => (
              <tr key={item.id}>
                <td>{item.fullName}</td>
                <td>
                  <OrderItemInventory inventory={item}></OrderItemInventory>
                </td>
                <td style={{ width: "125px" }}>
                  {item.fileName === null ||
                  typeof item.fileName === "undefined" ? (
                    <Button onClick={() => openAddItemFileModal(item)}>
                      Dodaj plik
                    </Button>
                  ) : (
                    <div className="d-flex align-items-center">
                      <Button
                        style={{ marginRight: "5px" }}
                        onClick={() => openGetItemFileModal(item)}
                      >
                        Podgląd
                      </Button>
                      {order.status === 0 && (
                        <Button onClick={() => openRemoveItemFileModal(item)}>
                          Usuń
                        </Button>
                      )}
                    </div>
                  )}
                </td>
                {order.status === 0 && (
                  <td className="col-md-3" style={{ textAlign: "end" }}>
                    <Button
                      className="me-2"
                      onClick={() => openEditItemInventoryModal(item)}
                    >
                      Wybierz asortyment
                    </Button>

                    <MdDeleteOutline
                      className="table-icon "
                      onClick={() => openRemoveItemModal(item)}
                      style={{ margin: "0 10px", padding: 0 }}
                    />
                  </td>
                )}
              </tr>
            ))}
        </tbody>
      </table>
      {!order.items ||
        (order.items.length === 0 && <p>Tu jeszcze nic nie ma</p>)}
      {order.status === OrderStatusType.CREATED && (
        <Button onClick={() => setSubmitModalVisible(true)}>
          Wyślij zamówienie do realizacji
        </Button>
      )}
      <div>
        <OrderItemAddModal
          setModalVisible={setAddItemModalVisible}
          visible={addItemModalVisible}
          addOrderItem={addOrderItem}
          orderId={order.id}
        />
        <OrderItemRemoveModal
          setModalVisible={setRemoveItemModalVisible}
          visible={removeItemModalVisible}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          removeOrderItem={removeOrderItem}
        />
        <OrderItemInventoryModal
          setModalVisible={setItemInventoryModalVisible}
          visible={itemInventoryModalVisible}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          setOrderItem={setOrderItem}
        />
        <FileAddModal
          setModalVisible={setAddItemFileModalVisible}
          visible={addItemFileModalVisible}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          setItem={setOrderItem}
          url={"/api/order-items/save-file/"}
          title={"kartą pomiarów"}
        />
        <FileViewModal
          setModalVisible={setGetItemFileModalVisible}
          visible={getItemFileModalVisible}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          url={"/api/order-items/get-file"}
        />
        <FileRemoveModal
          setModalVisible={setRemoveItemFileModalVisible}
          visible={removeItemFileModalVisible}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          setItem={setOrderItem}
          url={"/api/order-items/delete-file"}
          title={"kartę pomiarów"}
        />
        <FileAddModal
          setModalVisible={setAddComplaintFileModalVisible}
          visible={addComplaintFileModalVisible}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          setItem={setOrder}
          url={"/api/orders/add-complaint/"}
          title={"zgłoszeniem reklamacji"}
        />
        <FileViewModal
          setModalVisible={setGetComplaintFileModalVisible}
          visible={getComplaintFileModalVisible}
          currentItem={currentItem}
          setCurrentItem={setCurrentItem}
          url={"/api/orders/get-complaint"}
        />
      </div>
      <Modal isOpen={submitModalVisible}>
        <ModalBody>
          Czy na pewno chcesz wysłać zamówienie do realizacji?<br></br>
          {/* Upewnij się czy zamówienie jest kompletne... */}
          {/* Wysłanie zamówienia spowoduje... */}
          {errorMessage && (
            <FormGroup>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </FormGroup>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={cancelSubmitOrder}>
            Anuluj
          </Button>
          <Button onClick={handleSubmitOrder}>Wyślij</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OrderDetails;
