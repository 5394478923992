import Home from "./components/Home";
import Measurement from "./components/Measurement";
import Instruction from "./components/Instruction";
import Complaint from "./components/Complaint";
import AdminBranchAdd from "./components/admin/AdminBranchAdd";
import AdminBranchEdit from "./components/admin/AdminBranchEdit";
import AdminBranchList from "./components/admin/AdminBranchList";
import AdminBranchUserList from "./components/admin/AdminBranchUserList";
import AdminOrderList from "./components/admin/AdminOrderList";
import AdminUserList from "./components/admin/AdminUserList";
import AdminOrderDetails from "./components/admin/AdminOrderDetails";
import Contact from "./components/Contact";
import OrderDetails from "./components/order/OrderDetails";
import OrderList from "./components/order/OrderList";
import ResetPassword from "./components/auth/ResetPassword";
import ChangePassword from "./components/auth/ChangePassword";
import PageNotFound from "./components/PageNotFound";

const AppRoutes = [
  {
    index: true,
    element: <Home />,
  },
  {
    path: "/pomiar",
    element: <Measurement />,
  },
  {
    path: "/instrukcja",
    element: <Instruction />,
  },
  {
    path: "/reklamacja",
    element: <Complaint />,
  },
  {
    path: "/admin/oddzialy",
    element: <AdminBranchList />,
  },
  {
    path: "/admin/oddzialy/dodaj",
    element: <AdminBranchAdd />,
  },
  {
    path: "/admin/oddzialy/edytuj/:id",
    element: <AdminBranchEdit />,
  },
  {
    path: "/admin/oddzialy/:id/zamowienia",
    element: <AdminOrderList />,
  },
  {
    path: "/admin/oddzialy/:id/uzytkownicy",
    element: <AdminBranchUserList />,
  },
  {
    path: "/admin/zamowienia",
    element: <AdminOrderList />,
  },
  {
    path: "/admin/zamowienia/:id",
    element: <AdminOrderDetails />,
  },
  {
    path: "/admin/uzytkownicy",
    element: <AdminUserList />,
  },
  {
    path: "/zamowienia",
    element: <OrderList />,
  },
  {
    path: "/zamowienia/:id",
    element: <OrderDetails />,
  },
  {
    path: "/kontakt",
    element: <Contact />,
  },
  {
    path: "/reset-hasla",
    element: <ResetPassword />,
  },
  {
    path: "/zmien-haslo",
    element: <ChangePassword />,
  },
  {
    path: "*",
    element: <PageNotFound />,
  },
];

export default AppRoutes;
