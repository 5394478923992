const OrderStatusType = {
  CREATED: 0,
  SUBMITTED: 1,
  COMPLAINT_ISSUED: 2,
  IN_PROGRESS: 3,
  COMPLAINT_ACCEPTED_INPROGRESS: 4,
  SENT: 5,
  COMPLAINT_ACCEPTED_SENT: 6,
  COMPLAINT_REJECTED: 7,
  FINISHED: 8,
};

const OrderStatusTypeLabel = {
  0: "W edycji",
  1: "Zgłoszone",
  2: "Reklamacja zgłoszona",
  3: "W realizacji",
  4: "Reklamacja zaakceptowana - W realizacji",
  5: "Wysłane",
  6: "Reklamacja zaakceptowana - Wysłane",
  7: "Reklamacja odrzucona",
  8: "Zakończone",
};

export default OrderStatusType;

export const getOrderStatusLabel = (status) => OrderStatusTypeLabel[status];
