import React from "react";
import { Link } from "react-router-dom";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { Button } from "reactstrap";
import { saveAs } from "file-saver";
import { BsDownload } from "react-icons/bs";

import orderProtocol from "../assets/files/PROTOKOL ZDAWCZO-ODBIORCZY - WZOR.docx";

const Instruction = () => {
  return (
    <div>
      <h3>
        INSTRUKCJA DLA DYREKTORA ODDZIAŁU{" "}
        <p
          style={{
            fontSize: 15,
            color: "#403F6F",
            textTransform: "none",
          }}
          className="mb-0"
        >
          <HiOutlineArrowLongRight size={24} /> jak zrealizować zamówienie
        </p>
      </h3>
      <p>Dzień dobry,</p>
      <p>
        Z przyjemnością przesyłamy do Twojego oddziału sety pomiarowe
        zawierające wzorcowe elementy stroju korporacyjnego.
      </p>
      <p>
        Dlaczego to robimy? Bo chcemy, aby każdy mógł wybrać najlepiej
        dopasowany do siebie element ubioru, w którym będzie czuł się naprawdę
        komfortowo, co pomoże mu w codziennej pracy.
      </p>
      <p>Co zawiera set?</p>
      <ul>
        <li>
          Komplety damskie (żakiet i spódnica oraz spodnie) w rozmiarach 34-52 (
          10 sztuk)
        </li>
        <li>Bluzki damskie w rozmiarach 34-52 ( 10 sztuk)</li>
        <li>
          Komplet męski (marynarka i spodnie) w rozmiarach 50-60 ( 5 sztuk)
        </li>
        <li>Koszule męskie wersja Slim w rozmiarach 39-46 ( 8 sztuk)</li>
        <li>Koszule męskie wersja Regular w rozmiarach 39-50 ( 12 sztuk)</li>
      </ul>
      <p>
        Szczegółową instrukcję pomiarów dla pracowników Twojego Oddziału wraz z
        kartami pomiarowymi oddzielnie dla kobiet i mężczyzn. Znajdziesz w
        zakładce
        <Link
          className="text-decoration-none"
          style={{ margin: "0 5px" }}
          role="button"
          to="/pomiar"
        >
          Instrukcja pomiaru
        </Link>
      </p>
      <p>
        Sety pomiarowe mogą pozostać u Was przez maksymalnie 3 dni robocze. Po
        dokonaniu przymiarek zbierz od pracowników wypełnione karty pomiarowe i
        w ciągu 5 dni roboczych uzupełnij dane w Repozytorium.
      </p>
      <p>
        W trzecim dniu kurier odbierze od Ciebie sety. Przygotuj sety do wysyłki
        , pakując je tak samo jak otrzymałeś. Na Twój adres e-mail otrzymasz
        etykietę, którą musisz wydrukować i przykleić do wsyłanej paczki.
      </p>
      <div style={{ background: "#f8f9fa" }} className="card-body border">
        <h4>Składanie zamówienia</h4>
        <p>Co zrobić, aby poprawnie złożyć zamówienie?</p>
        <ol>
          <li>
            Jeśli otrzymałeś hasło na adres e-mail zaloguj się i zmień hasło na
            własne, znane tylko Tobie.
          </li>
          <li>
            W zakładce „Zamówienie” uzupełnij imię i nazwisko pracownika oraz
            asortyment, rozmiar i ilość jaką chcesz dla niego zamówić.
          </li>
          <li>
            Pamiętaj, by uzupełnić uwagi, jeśli pojawiły się na danej karcie
            pomiarów.
          </li>
          <li>
            Zamieść skan kart pomiarowych na stronie. Dokumenty do wypełnienia
            znajdziesz w
            <Link
              className="text-decoration-none"
              style={{ margin: "0 5px" }}
              role="button"
              to="/pomiar"
            >
              Instrukcji pomiaru
            </Link>
          </li>
          <li>
            Po wpisaniu danych i ostatecznym zatwierdzeniu zamówienia. Dostawca
            otrzyma automatyczne potwierdzenie złożenia zamówienia.
          </li>
          <li>W każdej chwili możesz sprawdzić aktualny Status zamówienia.</li>
          {/* <li>Po otrzymaniu zamówienia.</li>
          <div className="d-flex justify-content-start my-3">
            <Button onClick={onDownload}>
              <BsDownload className="btn-icon" />
              Protokół zdawczo-odbiorczy
            </Button>
          </div> */}
        </ol>
      </div>
      <div>
        <h4>Odbiór dostawy</h4>
        <p>
          Dostawca poinformuje Cię e-mailem o planowanej wysyłce strojów do
          Twojego Oddziału.
        </p>
        <p>
          Gdy stroje dotrą już do Ciebie i szczęśliwie odbierzesz przesyłkę,
          pamiętaj, aby pobrać i podpisać załączony na stronie Protokół
          Zdawczo-Odbiorczy.
        </p>
        <p>
          Skan podpisanego przez Ciebie Protokołu wyślij na adres:{" "}
          <a className="text-decoration-none" href="mailto:pzu@polanex.com.pl">
            pzu@polanex.com.pl
          </a>{" "}
          oraz do wiadomości{" "}
          <a
            className=" text-decoration-none"
            href="mailto:bws_wyposazenie@pzu.pl"
          >
            bws_wyposazenie@pzu.pl
          </a>
          . Skan zamieść na stronie.
          <Button onClick={() => saveAs(orderProtocol, "WZÓR - PROTOKÓŁ ZDAWCZO-ODBIORCZY.docx")}>
            <BsDownload className="btn-icon" />
            Wzór protokołu zdawczo-odbiorczego
          </Button>
        </p>
      </div>
      <div style={{ padding: "0 0 86px 0" }}>
        <p>
          W przypadku pytań lub wątpliwości, skontaktuj się bezpośrednio ze mną
          – postaram się pomóc najszybciej i najlepiej jak umiem.
        </p>
        <p>
          Dorota Pieczyńska
          <br /> tel. 790 203 830
          <br /> dorota.pieczynska@polanex.com.pl
        </p>
      </div>
    </div>
  );
};

export default Instruction;
