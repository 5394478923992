import React, { useEffect, useState, useContext } from "react";
import {
  Input,
  Button,
  FormGroup,
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
} from "reactstrap";
import { saveAs } from "file-saver";
import axios from "axios";
import { BsDownload } from "react-icons/bs";
import { HiOutlineArrowLongRight } from "react-icons/hi2";

import { AuthContext } from "./context/GlobalState";

const Home = () => {
  const [fileName, setFileName] = useState(null);
  const [selectedImage, setSelectedImage] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [visibleModal, setVisibleModal] = useState(false);
  const [auth] = useContext(AuthContext);

  useEffect(() => {
    axios
      .get("/api/schedule/", {
        responseType: "blob",
      })
      .then((response) => {
        let header = response.headers.get("Content-Disposition");
        header = header.substring(header.indexOf(";") + 1);
        const name = header.substring(10, header.indexOf(";"));
        setFileName(name);
        setSelectedImage(response.data);
      })
      .catch((error) => {});
  }, []);

  const downloadImage = () => {
    saveAs(selectedImage, fileName);
  };

  const uploadFile = () => {
    setErrorMessage(null);
    const formData = new FormData();
    formData.append("formFile", selectedImage, fileName);
    axios
      .post("/api/schedule/", formData, {
        headers: { "Content-Type": "multipart/form-data" },
      })
      .then((response) => {
        setVisibleModal(false);
      })
      .catch((error) => {
        if (error.message === "Network Error")
          setErrorMessage("Plik jest zbyt duży.");
        else setErrorMessage(error.response.data.message);
      });
  };

  const deleteFile = () => {
    setErrorMessage(null);
    axios
      .post("/api/schedule/delete")
      .then((response) => {
        setSelectedImage(null);
        setFileName(null);
      })
      .catch((error) => {});
  };

  return (
    <div>
      <div className="d-flex justify-content-between">
        <h3>
          Harmonogram
          <p
            style={{
              fontSize: 15,
              color: "#403F6F",
              textTransform: "none",
            }}
            className="mb-0"
          >
            <HiOutlineArrowLongRight size={24} /> sprawdź terminy dostaw
          </p>
        </h3>
        <div className="my-5">
          {auth.isAdmin && (
            <Button
              style={{ marginRight: "1rem" }}
              onClick={() => setVisibleModal(true)}
            >
              Zarządzaj
            </Button>
          )}

          {selectedImage && (
            <Button onClick={downloadImage}>
              <BsDownload className="btn-icon" />
              Pobierz
            </Button>
          )}
        </div>
      </div>
      <div>
        {selectedImage && (
          <img
            className="card-img"
            alt="not fount"
            width={"250px"}
            src={URL.createObjectURL(selectedImage)}
          />
        )}
      </div>

      <Modal size="lg" isOpen={visibleModal}>
        <ModalHeader>Podgląd</ModalHeader>
        <ModalBody>
          {selectedImage && (
            <img
              className="card-img"
              alt="harmonogram"
              width={"250px"}
              src={URL.createObjectURL(selectedImage)}
            />
          )}
        </ModalBody>
        <ModalFooter>
          <Input
            name="timeTable"
            type="file"
            onChange={(event) => {
              console.log(event.target.files[0]);
              setSelectedImage(event.target.files[0]);
              setFileName(event.target.files[0].name);
            }}
          />
          {errorMessage && (
            <FormGroup>
              <span style={{ color: "red" }}>{errorMessage}</span>
            </FormGroup>
          )}

          <div
            style={{ width: "100%" }}
            className="d-flex justify-content-between"
          >
            <div>
              <Button onClick={deleteFile}>Usuń ze strony</Button>
            </div>
            <div>
              <Button
                className="me-2"
                color="danger"
                outline
                onClick={() => setVisibleModal(false)}
              >
                Anuluj
              </Button>
              <Button onClick={uploadFile}>Zatwierdź</Button>
            </div>
          </div>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default Home;
