import React, { useState, useContext } from "react";
import {
  Collapse,
  Navbar,
  NavbarBrand,
  NavbarToggler,
  NavItem,
  NavLink,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
import { Link } from "react-router-dom";
import { BsPersonCircle } from "react-icons/bs";

import Logo from "../assets/polanex_logo.png";
import "./NavMenu.css";
import { AuthContext } from "./context/GlobalState";
import axios from "axios";

const NavMenu = () => {
  const [collapsed, setCollapsed] = useState(true);
  const [auth] = useContext(AuthContext);

  const toggle = () => setCollapsed((collapsed) => !collapsed);

  const logout = () => {
    axios.post("api/account/logout").then((response) => {
      window.location = "/";
    });
  };

  return (
    <>
      {auth?.authorized && (
        <header>
          <Navbar
            style={{ padding: "0px" }}
            className="navbar-expand-sm navbar-toggleable-sm ng-white border-bottom box-shadow mb-3"
            container
            light
          >
            <NavbarBrand tag={Link} to="/">
              <img src={Logo} alt="Logo" className={"navbar-logo"} />
            </NavbarBrand>
            <NavbarToggler onClick={toggle} className="mr-2" />
            <Collapse
              className="d-sm-inline-flex flex-sm-row-reverse"
              isOpen={!collapsed}
              navbar
            >
              <ul className="navbar-nav flex-grow">
                <NavItem>
                  <NavLink tag={Link} className="text-dark nav-text" to="/">
                    Harmonogram
                  </NavLink>
                </NavItem>
                <NavItem>
                  <UncontrolledDropdown setActiveFromChild>
                    <DropdownToggle
                      tag="a"
                      className="nav-link text-dark nav-text"
                      caret
                    >
                      Instrukcja
                    </DropdownToggle>
                    <DropdownMenu>
                      <DropdownItem
                        tag={Link}
                        className="text-dark nav-text"
                        to="/instrukcja"
                      >
                        Zamówienia
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        className="text-dark nav-text"
                        to="/pomiar"
                      >
                        Pomiaru
                      </DropdownItem>
                      <DropdownItem
                        tag={Link}
                        className="text-dark nav-text"
                        to="/reklamacja"
                      >
                        Reklamacji
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavItem>
                {(auth.isAdmin || auth.isObserver) && (
                  <NavItem>
                    <NavLink
                      tag={Link}
                      className="text-dark nav-text"
                      to="/admin/oddzialy"
                    >
                      Oddziały
                    </NavLink>
                  </NavItem>
                )}
                {(auth.isAdmin || auth.isObserver) && (
                  <NavItem>
                    <NavLink
                      tag={Link}
                      className="text-dark nav-text"
                      to="/admin/zamowienia"
                    >
                      Zamówienia
                    </NavLink>
                  </NavItem>
                )}
                {!(auth.isAdmin  || auth.isObserver) && (
                  <NavItem>
                    <NavLink
                      tag={Link}
                      className="text-dark nav-text"
                      to="/zamowienia"
                    >
                      Zamówienia
                    </NavLink>
                  </NavItem>
                )}
                {(auth.isAdmin || auth.isObserver) && (
                  <NavItem>
                    <NavLink
                      tag={Link}
                      className="text-dark nav-text"
                      to="/admin/uzytkownicy"
                    >
                      Użytkownicy
                    </NavLink>
                  </NavItem>
                )}
                <NavItem>
                  <NavLink
                    tag={Link}
                    className="text-dark nav-text"
                    to="/kontakt"
                  >
                    Kontakt
                  </NavLink>
                </NavItem>
                <NavItem>
                  <UncontrolledDropdown
                    className="ps-5 position-relative"
                    setActiveFromChild
                  >
                    <DropdownToggle
                      tag="a"
                      className="nav-link text-dark nav-text p-1"
                      caret
                    >
                      <BsPersonCircle className="text-dark" size={30} />
                    </DropdownToggle>
                    <DropdownMenu className="position-absolute top-20 end-0">
                      <DropdownItem
                        tag={Link}
                        className="text-dark nav-text"
                        to="/zmien-haslo"
                      >
                        Zmień hasło
                      </DropdownItem>
                      <DropdownItem divider />
                      <DropdownItem
                        className="text-dark nav-text"
                        onClick={logout}
                      >
                        {/* <BiLogOut size={23} className="ms-2" /> */}
                        Wyloguj
                      </DropdownItem>
                    </DropdownMenu>
                  </UncontrolledDropdown>
                </NavItem>
              </ul>
            </Collapse>
          </Navbar>
        </header>
      )}
    </>
  );
};

export default NavMenu;
