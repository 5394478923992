import React, { useState } from "react";
import {
  Form,
  FormGroup,
  Label,
  Input,
  Button,
  Modal,
  ModalHeader,
  ModalBody,
  ModalFooter,
} from "reactstrap";
import axios from "axios";

const AdminBranchUserAddModal = ({
  branchId,
  setModalVisible,
  visible,
  addUser
}) => {
  const [managerFullName, setManagerFullName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage(null);
    axios
      .post("/api/admin/branches/add-manager", {
        branchId,
        managerEmail,
        managerFullName,
      })
      .then((response) => {
        addUser({
          ...response.data,
        });
        setModalVisible(false);
        setManagerFullName("");
        setManagerEmail("");
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  const handleCancel = () => {
    setErrorMessage(null);
    setModalVisible(false);
    setManagerFullName("");
    setManagerEmail("");
  };

  return (
      <Modal isOpen={visible}>
        <ModalHeader>Dodaj managera</ModalHeader>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            <FormGroup>
              <Label>Imię i nazwisko dyrektora</Label>
              <Input
                name="managerFullName"
                type="text"
                onChange={(event) => setManagerFullName(event.target.value)}
              />
            </FormGroup>
            <FormGroup>
              <Label>Email dyrektora</Label>
              <Input
                name="managerEmail"
                type="text"
                onChange={(event) => setManagerEmail(event.target.value)}
              />
            </FormGroup>
            {errorMessage && (
              <FormGroup>
                <span style={{ color: "red" }}>{errorMessage}</span>
              </FormGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={handleCancel}>
            Anuluj
          </Button>
          <Button onClick={handleSubmit}>Dodaj</Button>
        </ModalFooter>
      </Modal>
  );
};

export default AdminBranchUserAddModal;
