import React from "react";
import AppContainer from "./AppContainer";
import Layout from "./components/Layout";
import "./custom.css";
import { GlobalProvider } from "./components/context/GlobalState";

const App = () => {
  return (
    <GlobalProvider>
      <div className="app-main">
        <Layout>
          <AppContainer></AppContainer>
        </Layout>
      </div>
    </GlobalProvider>
  );
};

export default App;
