import React from "react";
import { Button } from "reactstrap";
import { saveAs } from "file-saver";
import { BsDownload } from "react-icons/bs";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { Link } from "react-router-dom";

import ComplaintForm from "../assets/files/reklamacja.docx";

const Complaint = () => {
  const onDownload = () => {
    saveAs(ComplaintForm, "ZGŁOSZENIE REKLAMACYJNE.docx");
  };

  return (
    <div>
      <h3>
        Instrukcja składania reklamacji
        <p
          style={{
            fontSize: 15,
            color: "#403F6F",
            textTransform: "none",
          }}
          className="mb-0"
        >
          <HiOutlineArrowLongRight size={24} /> coś poszło niezgodnie z planem?
        </p>
      </h3>
      <p>Pobierz i uzupełnij poniższy plik.</p>
      <div className="d-flex justify-content-start my-3">
        <Button onClick={onDownload}>
          <BsDownload className="btn-icon" />
          Druk reklamacyjny
        </Button>
      </div>
      <p>
        Wyślij reklamowany asortyment wraz ze Zgłoszeniem reklamacyjnym na adres
        Dostawcy:
      </p>
      <p className="mx-4">
        Polanex sp. z o. o. <br /> Ul. Słoneczna 40 <br /> 62-200 Gniezno <br />
        tel. 790 203 830
      </p>
      <p>
        Po uznaniu reklamacji wyślemy do Ciebie naprawioną lub wymienioną część
        odzieży.
      </p>
      <p>
        W przypadku pytań lub wątpliwości, skontaktuj się bezpośrednio za
        pośrednictwem zakładki
        <Link style={{ margin: "0 5px" }} role="button" to="/kontakt">
          KONTAKT
        </Link>
        .
      </p>
    </div>
  );
};

export default Complaint;
