import Contact from "./components/Contact";
import ResetPassword from "./components/auth/ResetPassword";
import ForgotPassword from "./components/auth/ForgotPassword";
import Login from "./components/auth/Login";

const AppRoutesUnauthorized = [
  {
    index: true,
    element: <Login />,
  },
  {
    path: "/kontakt",
    element: <Contact />,
  },
  {
    path: "/reset-hasla",
    element: <ResetPassword />,
  },
  {
    path: "/zapomnialem-hasla",
    element: <ForgotPassword />,
  },
  {
    path: "*",
    element: <Login />,
  },
];

export default AppRoutesUnauthorized;
