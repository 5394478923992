import React, { useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Card,
  CardBody,
} from "reactstrap";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill } from "react-icons/bs";

const ResetPassword = () => {
  const [email, setEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  const [successMessage, setSuccessMessage] = useState(null);
  let navigate = useNavigate();

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage(null);
    setSuccessMessage(null);
    axios
      .post("/api/account/request-reset-password", { email })
      .then((response) => {
        setSuccessMessage(
          "Na twój adres e-mail wysłaliśmy maila z linkiem do zmiany hasła."
        );
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <div className="min-vh-90 py-5">
      <BsArrowLeftCircleFill
        className="btn-arrow"
        onClick={() => navigate(-1)}
      />
      <div
        style={{ height: "70vh" }}
        className="d-flex flex-column justify-content-center align-items-center"
      >
        <Card style={{ width: "400px" }}>
          <CardBody>
            <h4>Zresetuj swoje hasło</h4>
            <Form onSubmit={handleSubmit}>
              <FormGroup className="d-flex flex-column  align-items-center">
                <Col sm={11}>
                  <Label for="email">Twój adres e-mail</Label>
                </Col>
                <Col sm={11}>
                  <Input
                    name="email"
                    type="email"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                </Col>
              </FormGroup>
              <div className="d-flex flex-column  align-items-center mb-5">
                {successMessage && (
                  <FormGroup>
                    <span style={{ color: "green" }}>{successMessage}</span>
                  </FormGroup>
                )}
                {errorMessage && (
                  <FormGroup>
                    <span style={{ color: "red" }}>{errorMessage}</span>
                  </FormGroup>
                )}
                <Col sm={11}>
                  <Button className="w-100">
                    Wyślij e-mail z przypomnieniem
                  </Button>
                </Col>
              </div>
            </Form>
          </CardBody>
        </Card>
      </div>
    </div>
  );
};

export default ResetPassword;
