import React, { useState } from "react";
import {
  Col,
  Nav,
  NavItem,
  NavLink,
  Row,
  TabContent,
  TabPane,
  Button,
} from "reactstrap";
import { saveAs } from "file-saver";
import { BsDownload } from "react-icons/bs";
import { HiOutlineArrowLongRight } from "react-icons/hi2";

import measurementManForm from "../assets/files/karta_pomiaru_meska.docx";
import measurementManInstruction from "../assets/files/instrukcja_meska.docx";
import measurementWomanForm from "../assets/files/karta_damska.docx";
import measurementWomanInstruction from "../assets/files/instrukcja_damska.docx";

const Measurement = () => {
  const [currentActiveTab, setCurrentActiveTab] = useState("1");

  const onDownload = (param) => {
    switch (param) {
      case "man_form":
        return saveAs(measurementManForm, "KARTA POMIARU MĘSKA_MKK.docx");
      case "man_intruction":
        return saveAs(
          measurementManInstruction,
          "Instrukcja pomiaru komplet męski.docx"
        );
      case "woman_form":
        return saveAs(measurementWomanForm, "KARTA POMIARU DAMSKA_MKK.docx");
      case "woman_intruction":
        return saveAs(
          measurementWomanInstruction,
          "Instrukcja pomiaru komplet damski.docx"
        );
      default:
        return null;
    }
  };

  const toggle = (tab) => {
    if (currentActiveTab !== tab) setCurrentActiveTab(tab);
  };

  return (
    <div>
      <h3>
        Instrukcja pomiarów
        <p
          style={{
            fontSize: 15,
            color: "#403F6F",
            textTransform: "none",
          }}
          className="mb-0"
        >
          <HiOutlineArrowLongRight size={24} /> dobierz odpowiedni rozmiar
        </p>
      </h3>
      <Nav tabs>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={
              currentActiveTab === "1" ? "active text-dark" : "text-muted"
            }
            onClick={() => toggle("1")}
          >
            Komplet męski
          </NavLink>
        </NavItem>
        <NavItem>
          <NavLink
            style={{ cursor: "pointer" }}
            className={
              currentActiveTab === "2" ? "active text-dark" : "text-muted"
            }
            onClick={() => toggle("2")}
          >
            Komplet damski
          </NavLink>
        </NavItem>
      </Nav>
      <TabContent
        style={{
          background: "white",
          border: "1px solid #dee2e6",
          borderWidth: "0px 1px 1px 1px",
        }}
        activeTab={currentActiveTab}
      >
        <TabPane tabId="1">
          <Row>
            <div className="d-flex  justify-content-around mt-5">
              <Button onClick={() => onDownload("man_intruction")}>
                <BsDownload className="btn-icon" />
                Instrukcja pomiarów – KOMPLET MĘSKI
              </Button>
              <Button onClick={() => onDownload("man_form")}>
                <BsDownload className="btn-icon" />
                Karta pomiarów – KOMPLET MĘSKI
              </Button>
            </div>
            <Col className="m-3">
              <h5>Koszula</h5>
              <div>
                <p>
                  Wybierz koszulę, która najlepiej na Ciebie pasuje i zaznacz
                  jej właściwy rozmiar na karcie pomiaru. Rozmiar znajdziesz na
                  metce dołączonej do koszuli.
                </p>
                <p>
                  Koniecznie zaznacz także, czy wybierasz wersję Regular czy
                  Slim.
                </p>
                <h6>REGULAR</h6>
                <table className="table" style={{ fontSize: "12px" }}>
                  <thead>
                    <tr>
                      <th scope="col">Rozmiar kołnierza</th>
                      <th scope="col">39</th>
                      <th scope="col">40</th>
                      <th scope="col">41</th>
                      <th scope="col">42</th>
                      <th scope="col">43</th>
                      <th scope="col">44</th>
                      <th scope="col">45</th>
                      <th scope="col">46</th>
                      <th scope="col">47</th>
                      <th scope="col">48</th>
                      <th scope="col">49</th>
                      <th scope="col">50</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Obwód klatki piersiowej</th>
                      <td>114</td>
                      <td>118</td>
                      <td>122</td>
                      <td>126</td>
                      <td>130</td>
                      <td>134</td>
                      <td>138</td>
                      <td>142</td>
                      <td>146</td>
                      <td>150</td>
                      <td>154</td>
                      <td>158</td>
                    </tr>
                    <tr>
                      <th scope="row">Obwód w talii</th>
                      <td>106</td>
                      <td>110</td>
                      <td>114</td>
                      <td>118</td>
                      <td>122</td>
                      <td>126</td>
                      <td>130</td>
                      <td>134</td>
                      <td>138</td>
                      <td>142</td>
                      <td>150</td>
                      <td>154</td>
                    </tr>
                    <tr>
                      <th scope="row">Obwód bioder</th>
                      <td>108</td>
                      <td>112</td>
                      <td>116</td>
                      <td>120</td>
                      <td>124</td>
                      <td>128</td>
                      <td>132</td>
                      <td>136</td>
                      <td>140</td>
                      <td>144</td>
                      <td>150</td>
                      <td>154</td>
                    </tr>
                    <tr>
                      <th scope="row">Długość mankietów</th>
                      <td>26</td>
                      <td>26</td>
                      <td>26</td>
                      <td>26</td>
                      <td>28</td>
                      <td>28</td>
                      <td>28</td>
                      <td>28</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                      <td>30</td>
                    </tr>
                  </tbody>
                </table>
                <Row>
                  <Col>
                    <table className="table" style={{ fontSize: "12px" }}>
                      <thead>
                        <tr>
                          <th className="text-center" colSpan="2">
                            DLA ROZMIARÓW 39-46
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">WZROST</th>
                          <td>176-182</td>
                        </tr>
                        <tr>
                          <th scope="row">Długość koszuli</th>
                          <td>81</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                  <Col>
                    <table className="table" style={{ fontSize: "12px" }}>
                      <thead>
                        <tr>
                          <th className="text-center" colSpan="2">
                            DLA ROZMIARÓW 47-50
                          </th>
                        </tr>
                      </thead>
                      <tbody>
                        <tr>
                          <th scope="row">WZROST</th>
                          <td>184-192</td>
                        </tr>
                        <tr>
                          <th scope="row">Długość koszuli</th>
                          <td>85</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </Row>
                <h6>SLIM</h6>
                <div>
                  <table className="table" style={{ fontSize: "12px" }}>
                    <thead>
                      <tr>
                        <th scope="col">Rozmiar</th>
                        <th scope="col">36</th>
                        <th scope="col">37</th>
                        <th scope="col">38</th>
                        <th scope="col">39</th>
                        <th scope="col">40</th>
                        <th scope="col">41</th>
                        <th scope="col">42</th>
                        <th scope="col">43</th>
                        <th scope="col">44</th>
                        <th scope="col">45</th>
                        <th scope="col">46</th>
                        <th scope="col">47</th>
                        <th scope="col">48</th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr>
                        <th scope="row">Obwód klatki piersiowej</th>
                        <td>102</td>
                        <td>106</td>
                        <td>108</td>
                        <td>112</td>
                        <td>114</td>
                        <td>118</td>
                        <td>120</td>
                        <td>124</td>
                        <td>126</td>
                        <td>130</td>
                        <td>134</td>
                        <td>140</td>
                        <td>144</td>
                      </tr>
                      <tr>
                        <th scope="row">Obwód w talii</th>
                        <td>92</td>
                        <td>96</td>
                        <td>98</td>
                        <td>104</td>
                        <td>106</td>
                        <td>110</td>
                        <td>112</td>
                        <td>118</td>
                        <td>120</td>
                        <td>124</td>
                        <td>128</td>
                        <td>134</td>
                        <td>138</td>
                      </tr>
                      <tr>
                        <th scope="row">Obwód bioder</th>
                        <td>94</td>
                        <td>98</td>
                        <td>101</td>
                        <td>106</td>
                        <td>109</td>
                        <td>112</td>
                        <td>114</td>
                        <td>120</td>
                        <td>123</td>
                        <td>127</td>
                        <td>131</td>
                        <td>137</td>
                        <td>141</td>
                      </tr>
                      <tr>
                        <th scope="row">Długość mankietów</th>
                        <td>24</td>
                        <td>24</td>
                        <td>24</td>
                        <td>26</td>
                        <td>26</td>
                        <td>26</td>
                        <td>26</td>
                        <td>28</td>
                        <td>28</td>
                        <td>28</td>
                        <td>28</td>
                        <td>30</td>
                        <td>30</td>
                      </tr>
                    </tbody>
                  </table>
                  <Col sm={5}>
                    <table className="table" style={{ fontSize: "12px" }}>
                      <tbody>
                        <tr>
                          <th scope="row">WZROST</th>
                          <td>176-182</td>
                        </tr>
                        <tr>
                          <th scope="row">Długość koszuli</th>
                          <td>81</td>
                        </tr>
                        <tr>
                          <th scope="row">
                            Długość rękawa{" "}
                            <p style={{ fontSize: 12 }}>
                              (mierzona od wszycia do dolnej krawędzi mankietu)
                            </p>
                          </th>
                          <td>66</td>
                        </tr>
                        <tr>
                          <th scope="row">Długość rękawa krótkiego</th>
                          <td>28</td>
                        </tr>
                      </tbody>
                    </table>
                  </Col>
                </div>
              </div>
              <h5>Marynarka</h5>
              <p>
                Załóż marynarkę i sprawdź, w którym rozmiarze czujesz się
                najbardziej komfortowo. Następnie zaznacz ten rozmiar na karcie
                pomiaru.
              </p>
              <p>
                Jeżeli rękawy są za krótkie lub za długie możemy je wydłużyć lub
                skrócić o 3 cm. Koniecznie wpisz taką prośbę w polu Uwagi na
                karcie pomiaru.
              </p>
              <p>
                Marynarka za krótka lub za długa? Z tym również sobie poradzimy!
                Możemy ją wydłużyć lub skrócić o 4 cm. Koniecznie wpisz taką
                prośbę w polu Uwagi na karcie pomiaru.
              </p>

              <table className="table" style={{ fontSize: "12px" }}>
                <thead>
                  <tr>
                    <th scope="col">Rozmiar</th>
                    <th scope="col">50</th>
                    <th scope="col">52</th>
                    <th scope="col">54</th>
                    <th scope="col">56</th>
                    <th scope="col">58</th>
                    <th scope="col">60</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Obwód klatki</th>
                    <td>104</td>
                    <td>108</td>
                    <td>112</td>
                    <td>116</td>
                    <td>120</td>
                    <td>126</td>
                  </tr>
                  <tr>
                    <th scope="row">Obwód w talii</th>
                    <td>96</td>
                    <td>100</td>
                    <td>104</td>
                    <td>108</td>
                    <td>112</td>
                    <td>118</td>
                  </tr>
                  <tr>
                    <th scope="row">Obwód bioder</th>
                    <td>100</td>
                    <td>104</td>
                    <td>109</td>
                    <td>113</td>
                    <td>116</td>
                    <td>124</td>
                  </tr>
                  <tr>
                    <th scope="row">Długość rękawa długiego z mankietem</th>
                    <td>63</td>
                    <td>63,5</td>
                    <td>64</td>
                    <td>65</td>
                    <td>66</td>
                    <td>66</td>
                  </tr>
                  <tr>
                    <th scope="row">Długość tyłu mierzona od podkroju szyi</th>
                    <td>71,5</td>
                    <td>73</td>
                    <td>73</td>
                    <td>73,5</td>
                    <td>75</td>
                    <td>77</td>
                  </tr>
                  <tr>
                    <th scope="row">Szerokość pleców</th>
                    <td>41</td>
                    <td>42,5</td>
                    <td>43,5</td>
                    <td>45</td>
                    <td>46</td>
                    <td>48</td>
                  </tr>
                </tbody>
              </table>
              <h5>SPODNIE</h5>
              <p>
                Wybierz spodnie, w których najlepiej się czujesz i zaznacza ich
                właściwy rozmiar z metki na karcie pomiaru.
              </p>
              <p>
                Jeżeli uznasz, że nogawki są za krótkie lub za długie możemy je
                wydłużyć lub skrócić o 4 cm lub 8 cm. Napisz taką prośbę w polu
                Uwagi na karcie pomiaru.
              </p>
              <p>
                Jeżeli spodnie są za wąskie lub za szerokie w pasie, z tym też
                sobie poradzimy! Możemy je poszerzyć lub zwęzić o 2 cm. Napisz
                taką prośbę w polu Uwagi na karcie pomiaru.
              </p>
              <table className="table" style={{ fontSize: "12px" }}>
                <thead>
                  <tr>
                    <th scope="col">Rozmiar</th>
                    <th scope="col">50</th>
                    <th scope="col">52</th>
                    <th scope="col">54</th>
                    <th scope="col">56</th>
                    <th scope="col">58</th>
                    <th scope="col">60</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Pas</th>
                    <td>88</td>
                    <td>92</td>
                    <td>96</td>
                    <td>100</td>
                    <td>104</td>
                    <td>110</td>
                  </tr>
                  <tr>
                    <th scope="row">Obwód bioder</th>
                    <td>100</td>
                    <td>106</td>
                    <td>110</td>
                    <td>114</td>
                    <td>118</td>
                    <td>122</td>
                  </tr>
                  <tr>
                    <th scope="row">Obwód uda</th>
                    <td>62</td>
                    <td>64</td>
                    <td>65</td>
                    <td>67</td>
                    <td>70</td>
                    <td>72</td>
                  </tr>
                  <tr>
                    <th scope="row">Długość nogawki z paskiem</th>
                    <td>107</td>
                    <td>107</td>
                    <td>107</td>
                    <td>107</td>
                    <td>108</td>
                    <td>108</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </TabPane>
        <TabPane tabId="2">
          <Row>
            <div className="d-flex justify-content-around mt-5">
              <Button onClick={() => onDownload("woman_intruction")}>
                <BsDownload className="btn-icon" />
                Instrukcja pomiarów – KOMPLET DAMSKI
              </Button>
              <Button onClick={() => onDownload("woman_form")}>
                <BsDownload className="btn-icon" />
                Karta pomiarów – KOMPLET DAMSIK
              </Button>
            </div>
            <Col className="m-3">
              <h5>BLUZKA</h5>
              <p>
                Wybierz bluzkę, która na Ciebie pasuje, sprawdź, jaki rozmiar
                znajduje się na jej metce i zaznacz go na karcie pomiaru.
              </p>
              <div>
                <table className="table" style={{ fontSize: "12px" }}>
                  <thead>
                    <tr>
                      <th scope="col">Rozmiar</th>
                      <th scope="col">34</th>
                      <th scope="col">36</th>
                      <th scope="col">38</th>
                      <th scope="col">40</th>
                      <th scope="col">42</th>
                      <th scope="col">44</th>
                      <th scope="col">46</th>
                      <th scope="col">48</th>
                      <th scope="col">50</th>
                      <th scope="col">52</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Obwód w biuście</th>
                      <td>88</td>
                      <td>92</td>
                      <td>96</td>
                      <td>100</td>
                      <td>104</td>
                      <td>108</td>
                      <td>112</td>
                      <td>118</td>
                      <td>124</td>
                      <td>130</td>
                    </tr>
                    <tr>
                      <th scope="row">Obwód w talii</th>
                      <td>76</td>
                      <td>80</td>
                      <td>84</td>
                      <td>88</td>
                      <td>92</td>
                      <td>96</td>
                      <td>100</td>
                      <td>106</td>
                      <td>112</td>
                      <td>118</td>
                    </tr>
                    <tr>
                      <th scope="row">Obwód w dołu</th>
                      <td>95</td>
                      <td>97</td>
                      <td>100</td>
                      <td>103</td>
                      <td>107</td>
                      <td>111</td>
                      <td>115</td>
                      <td>121</td>
                      <td>127</td>
                      <td>133</td>
                    </tr>
                    <tr>
                      <th scope="row">Długość rękawa długiego z mankietem</th>
                      <td>58,5</td>
                      <td>59,5</td>
                      <td>60,5</td>
                      <td>61,5</td>
                      <td>62,5</td>
                      <td>63,5</td>
                      <td>64,5</td>
                      <td>65,5</td>
                      <td>66,5</td>
                      <td>67,5</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Długość tyłu mierzona od podkroju szyi
                      </th>
                      <td>60</td>
                      <td>61</td>
                      <td>62</td>
                      <td>63</td>
                      <td>64</td>
                      <td>65</td>
                      <td>66</td>
                      <td>66</td>
                      <td>67</td>
                      <td>67</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5>ŻAKIET</h5>
              <p>
                Załóż żakiet, który najbardziej na Ciebie pasuje i zaznacz
                właściwy rozmiar z metki na karcie pomiaru.
              </p>
              <p>
                Jeżeli rękawy są za krótkie lub za długie możemy je wydłużyć lub
                skrócić o 3 cm. Koniecznie wpisz taką prośbę w polu Uwagi na
                karcie pomiaru.
              </p>
              <p>
                Wybrany żakiet jest za krótki lub za długi? Z tym również sobie
                poradzimy! Możemy go wydłużyć lub skrócić o 4 cm. Koniecznie
                wpisz taką prośbę w polu Uwagi na karcie pomiaru.
              </p>
              <div>
                <table className="table" style={{ fontSize: "12px" }}>
                  <thead>
                    <tr>
                      <th scope="col">Rozmiar</th>
                      <th scope="col">34</th>
                      <th scope="col">36</th>
                      <th scope="col">38</th>
                      <th scope="col">40</th>
                      <th scope="col">42</th>
                      <th scope="col">44</th>
                      <th scope="col">46</th>
                      <th scope="col">48</th>
                      <th scope="col">50</th>
                      <th scope="col">52</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <th scope="row">Obwód w biuście</th>
                      <td>84</td>
                      <td>88</td>
                      <td>92</td>
                      <td>96</td>
                      <td>100</td>
                      <td>104</td>
                      <td>108</td>
                      <td>112</td>
                      <td>118</td>
                      <td>124</td>
                    </tr>
                    <tr>
                      <th scope="row">Obwód w talii</th>
                      <td>76</td>
                      <td>80</td>
                      <td>86</td>
                      <td>88</td>
                      <td>92</td>
                      <td>96</td>
                      <td>100</td>
                      <td>110</td>
                      <td>112</td>
                      <td>118</td>
                    </tr>
                    <tr>
                      <th scope="row">Obwód bioder</th>
                      <td>82</td>
                      <td>88</td>
                      <td>92</td>
                      <td>95</td>
                      <td>98</td>
                      <td>102</td>
                      <td>106</td>
                      <td>112</td>
                      <td>116</td>
                      <td>122</td>
                    </tr>
                    <tr>
                      <th scope="row">Długość rękawa długiego z mankietem</th>
                      <td>63</td>
                      <td>63</td>
                      <td>63,5</td>
                      <td>64</td>
                      <td>64,5</td>
                      <td>65</td>
                      <td>65</td>
                      <td>65,5</td>
                      <td>66</td>
                      <td>66</td>
                    </tr>
                    <tr>
                      <th scope="row">
                        Długość tyłu mierzona od podkroju szyi
                      </th>
                      <td>66</td>
                      <td>67</td>
                      <td>67</td>
                      <td>68</td>
                      <td>68</td>
                      <td>68</td>
                      <td>68</td>
                      <td>70</td>
                      <td>70</td>
                      <td>70</td>
                    </tr>
                    <tr>
                      <th scope="row">Szerokość pleców</th>
                      <td>37</td>
                      <td>38</td>
                      <td>39</td>
                      <td>40</td>
                      <td>42</td>
                      <td>43</td>
                      <td>44</td>
                      <td>45</td>
                      <td>47</td>
                      <td>48</td>
                    </tr>
                  </tbody>
                </table>
              </div>
              <h5>SPÓDNICA</h5>
              <p>
                Przymierz spódnicę i wybierz tę we właściwym rozmiarze.
                Następnie zaznacz wybrany rozmiar z metki na karcie pomiaru.
                Długość spódnicy jest standardowa.
              </p>
              <table className="table" style={{ fontSize: "12px" }}>
                <thead>
                  <tr>
                    <th scope="col">Rozmiar</th>
                    <th scope="col">34</th>
                    <th scope="col">36</th>
                    <th scope="col">38</th>
                    <th scope="col">40</th>
                    <th scope="col">42</th>
                    <th scope="col">44</th>
                    <th scope="col">46</th>
                    <th scope="col">48</th>
                    <th scope="col">50</th>
                    <th scope="col">52</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Pas</th>
                    <td>66</td>
                    <td>70</td>
                    <td>74</td>
                    <td>78</td>
                    <td>82</td>
                    <td>86</td>
                    <td>90</td>
                    <td>94</td>
                    <td>98</td>
                    <td>102</td>
                  </tr>
                  <tr>
                    <th scope="row">Obwód bioder</th>
                    <td>91</td>
                    <td>95</td>
                    <td>99</td>
                    <td>103</td>
                    <td>107</td>
                    <td>111</td>
                    <td>115</td>
                    <td>118</td>
                    <td>123</td>
                    <td>127</td>
                  </tr>
                  <tr>
                    <th scope="row">Długość z paskiem</th>
                    <td>55</td>
                    <td>55,5</td>
                    <td>57</td>
                    <td>57</td>
                    <td>57</td>
                    <td>58</td>
                    <td>59</td>
                    <td>60</td>
                    <td>60</td>
                    <td>61</td>
                  </tr>
                </tbody>
              </table>
              <h5>SPODNIE</h5>
              <p>
                Wybierz spodnie, w których najlepiej się czujesz i zaznacza ich
                właściwy rozmiar z metki na karcie pomiaru.
              </p>
              <p>
                Jeżeli uznasz, że nogawki są za krótkie lub za długie możemy je
                wydłużyć lub skrócić o 4 cm lub 8 cm. Napisz taką prośbę w polu
                Uwagi na karcie pomiaru.
              </p>
              <table className="table" style={{ fontSize: "12px" }}>
                <thead>
                  <tr>
                    <th scope="col">Rozmiar</th>
                    <th scope="col">34</th>
                    <th scope="col">36</th>
                    <th scope="col">38</th>
                    <th scope="col">40</th>
                    <th scope="col">42</th>
                    <th scope="col">44</th>
                    <th scope="col">46</th>
                    <th scope="col">48</th>
                    <th scope="col">50</th>
                    <th scope="col">52</th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <th scope="row">Pas</th>
                    <td>66</td>
                    <td>70</td>
                    <td>74</td>
                    <td>78</td>
                    <td>82</td>
                    <td>86</td>
                    <td>90</td>
                    <td>94</td>
                    <td>98</td>
                    <td>102</td>
                  </tr>
                  <tr>
                    <th scope="row">Obwód bioder</th>
                    <td>91</td>
                    <td>95</td>
                    <td>99</td>
                    <td>103</td>
                    <td>107</td>
                    <td>110</td>
                    <td>115</td>
                    <td>118</td>
                    <td>122</td>
                    <td>126</td>
                  </tr>
                  <tr>
                    <th scope="row">Obwód uda</th>
                    <td>56</td>
                    <td>60</td>
                    <td>62</td>
                    <td>63</td>
                    <td>65</td>
                    <td>67</td>
                    <td>68</td>
                    <td>70</td>
                    <td>70</td>
                    <td>72</td>
                  </tr>
                  <tr>
                    <th scope="row">Długość z paskiem</th>
                    <td>107</td>
                    <td>107</td>
                    <td>107</td>
                    <td>107</td>
                    <td>108</td>
                    <td>108</td>
                    <td>108</td>
                    <td>109</td>
                    <td>109</td>
                    <td>109</td>
                  </tr>
                </tbody>
              </table>
            </Col>
          </Row>
        </TabPane>
      </TabContent>
    </div>
  );
};

export default Measurement;
