import React, { useState, useContext } from "react";
import { Form, FormGroup, Label, Input, Button } from "reactstrap";
import { useNavigate } from "react-router-dom";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { GlobalContext } from "../context/GlobalState";
import axios from "axios";

const AdminBranchAdd = () => {
  const { addBranch } = useContext(GlobalContext);
  const [name, setName] = useState("");
  const [postCode, setPostCode] = useState("");
  const [city, setCity] = useState("");
  const [address, setAddress] = useState("");
  const [managerFullName, setManagerFullName] = useState("");
  const [managerEmail, setManagerEmail] = useState("");
  const [errorMessage, setErrorMessage] = useState(null);
  let navigate = useNavigate();

  const handleSubmit = (event) => {
    setErrorMessage(null);
    axios
      .post("/api/admin/branches", {
        name,
        managerEmail,
        managerFullName,
        address,
        postCode,
        city,
      })
      .then((response) => {
        addBranch({
          id: response.data,
          name,
          address,
          postCode,
          city
        });
        navigate("/admin/oddzialy");
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  return (
    <div>
      <BsArrowLeftCircleFill
        className="btn-arrow"
        onClick={() => navigate(-1)}
      />
      <Form onSubmit={handleSubmit}>
        <FormGroup>
          <Label>Oddział</Label>
          <Input
            name="name"
            type="text"
            onChange={(event) => setName(event.target.value)}
          />
          </FormGroup>
          <FormGroup>
          <Label>Kod pocztowy</Label>
          <Input
            name="postCode"
            type="text"
            onChange={(event) => setPostCode(event.target.value)}
          />
          </FormGroup>
          <FormGroup>
          <Label>Miasto</Label>
          <Input
            name="city"
            type="text"
            onChange={(event) => setCity(event.target.value)}
          />
          </FormGroup>
          <FormGroup>
          <Label>Ulica i numer</Label>
          <Input
            name="address"
            type="text"
            onChange={(event) => setAddress(event.target.value)}
          />
          </FormGroup>
          <FormGroup>
          <Label>Imię i nazwisko dyrektora</Label>
          <Input
            name="managerFullName"
            type="text"
            onChange={(event) => setManagerFullName(event.target.value)}
          />
          </FormGroup>
          <FormGroup>
          <Label>Email dyrektora</Label>
          <Input
            name="managerEmail"
            type="text"
            onChange={(event) => setManagerEmail(event.target.value)}
          />
        </FormGroup>
        {errorMessage && (
          <FormGroup>
            <span style={{ color: "red" }}>{errorMessage}</span>
          </FormGroup>
        )}
        <Button onClick={(event) => handleSubmit(event)}>Dodaj</Button>
      </Form>
    </div>
  );
};

export default AdminBranchAdd;
