import React, { useEffect, useState, useContext } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Button, FormGroup, Modal, ModalBody, ModalFooter } from "reactstrap";
import { HiOutlineArrowLongRight } from "react-icons/hi2";
import { BsArrowLeftCircleFill } from "react-icons/bs";
import { MdAdd, MdDeleteOutline } from "react-icons/md";
import axios from "axios";

import { AuthContext, GlobalContext } from "../context/GlobalState";
import AdminBranchUserAddModal from "./AdminBranchUserAddModal";

const AdminBranchUserList = () => {
  const [auth] = useContext(AuthContext);
  const navigate = useNavigate();
  const { id } = useParams();
  const { branches } = useContext(GlobalContext);
  const [branch, setBranch] = useState(null);
  const [addModalVisible, setAddModalVisible] = useState(false);
  const [removeModalVisible, setRemoveModalVisible] = useState(false);
  const [resendModalVisible, setResendModalVisible] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    setBranch(branches.find((x) => x.id === Number(id)));
  }, [id, branches]);

  useEffect(() => {
    setUsers(null);
    axios
      .get(`/api/admin/users/branch/${id}`)
      .then((response) => {
        setUsers(response.data);
      })
      .catch((error) => {
        setErrorMessage("Coś poszło nie tak. Przeładuj stronę.");
      });
  }, [id]);

  const openAddModal = () => {
    setAddModalVisible(true);
  };

  const addUser = (user) => {
    setUsers((previous) => [...previous, user]);
  };

  const openRemoveModal = (item) => {
    setCurrentItem(item);
    setRemoveModalVisible(true);
  };

  const removeUser = () => {
    setErrorMessage(null);
    axios
      .post("/api/admin/users/delete", { id: currentItem.id })
      .then((response) => {
        setUsers((previous) => {
          const index = previous.findIndex((x) => x.id === currentItem.id);
          if (index > -1) {
            previous.splice(index, 1);
            return [...previous];
          }
          return previous;
        });
        setRemoveModalVisible(false);
        setCurrentItem(null);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  const cancelRemoveModal = () => {
    setRemoveModalVisible(false);
    setErrorMessage(null);
    setCurrentItem(null);
  };

  const openResendModal = (item) => {
    setCurrentItem(item);
    setResendModalVisible(true);
  };

  const resendEmailConfirmation = () => {
    axios
      .post("/api/admin/users/resend-email-confirmation", {
        id: currentItem.id,
      })
      .then((response) => {
        setResendModalVisible(false);
        setCurrentItem(null);
      })
      .catch((error) => {
        setErrorMessage(error.response.data.message);
      });
  };

  const cancelResendModal = () => {
    setResendModalVisible(false);
    setErrorMessage(null);
    setCurrentItem(null);
  };

  if (users === null && errorMessage === null) return null;
  if (users === null && errorMessage !== null)
    return (
      <FormGroup>
        <span style={{ color: "red" }}>{errorMessage}</span>
      </FormGroup>
    );

  return (
    <div>
      <h3>
        {typeof branch !== "undefined" && branch !== null && branch.name}
        <p
          style={{
            fontSize: 15,
            color: "#403F6F",
            textTransform: "none",
          }}
          className="mb-0"
        >
          <HiOutlineArrowLongRight size={24} /> zarządzaj użytkownikami
        </p>
      </h3>
      <div
        style={{ height: "40px" }}
        className="d-flex justify-content-between mb-3"
      >
        <BsArrowLeftCircleFill
          className="btn-arrow"
          onClick={() => navigate(-1)}
        />
        {auth?.isAdmin && (
          <Button onClick={openAddModal}>
            <MdAdd className="btn-icon" />
            Dodaj managera
          </Button>
        )}
      </div>
      <table className="table table-striped">
        <thead className="table-light">
          <tr>
            <th>Email</th>
            <th>Imię i nazwisko</th>
            <th>E-mail zweryfikowany</th>
            {auth?.isAdmin && <th style={{ textAlign: "right" }}>Admin</th>}
          </tr>
        </thead>
        <tbody>
          {users &&
            users.length > 0 &&
            users.map((item) => {
              return (
                <tr key={item.id}>
                  <td>{item.email}</td>
                  <td>{item.fullName}</td>
                  <td>
                    {item.isEmailConfirmed ? (
                      <>✓</>
                    ) : (
                      auth?.isAdmin && (
                        <Button
                          onClick={() => openResendModal(item)}
                          style={{ margin: 0 }}
                        >
                          Wyślij ponownie
                        </Button>
                      )
                    )}
                  </td>
                  {auth?.isAdmin && (
                    <td style={{ textAlign: "right" }}>
                      <MdDeleteOutline
                        className="table-icon"
                        onClick={() => openRemoveModal(item)}
                        style={{ margin: 0 }}
                      />
                    </td>
                  )}
                </tr>
              );
            })}
        </tbody>
      </table>
      {(!users || users.length === 0) && (
        <div className="d-flex flex-column  justify-content-center align-items-center">
          <p>Tu jeszcze nic nie ma</p>
        </div>
      )}
      <Modal isOpen={removeModalVisible}>
        <ModalBody>
          {currentItem !== null && (
            <>
              Czy na pewno chcesz usunąć użytkownika{" "}
              <b>{currentItem.fullName}</b>? Użytkownik ten utraci dostęp do
              serwisu.
              {errorMessage && (
                <FormGroup>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                </FormGroup>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={cancelRemoveModal}>
            Anuluj
          </Button>
          <Button onClick={removeUser}>Akceptuj</Button>
        </ModalFooter>
      </Modal>
      <Modal isOpen={resendModalVisible}>
        <ModalBody>
          {currentItem !== null && (
            <>
              Czy na pewno chcesz ponownie wysłać potwierdzenie e-mail dla
              użytkownika <b>{currentItem.fullName}</b>?
              {errorMessage && (
                <FormGroup>
                  <span style={{ color: "red" }}>{errorMessage}</span>
                </FormGroup>
              )}
            </>
          )}
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={cancelResendModal}>
            Anuluj
          </Button>
          <Button onClick={resendEmailConfirmation}>Akceptuj</Button>
        </ModalFooter>
      </Modal>
      <AdminBranchUserAddModal
        setModalVisible={setAddModalVisible}
        visible={addModalVisible}
        addUser={addUser}
        branchId={id}
      ></AdminBranchUserAddModal>
    </div>
  );
};

export default AdminBranchUserList;
