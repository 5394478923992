import axios from "axios";
import React, { useEffect, useState } from "react";
import {
  Button,
  Form,
  FormGroup,
  Label,
  Input,
  Col,
  Modal,
  ModalBody,
  ModalFooter,
} from "reactstrap";

import {
  MALE_PARAMETERS,
  FEMALE_PARAMETERS,
  ALL_PARAMETERS,
} from "../models/OrderItemParameters";

const getNewInventory = () => ({
  shirt: "",
  shirtAmount: 0,
  shirtComments: "",
  shirtSlim: "",
  shirtSlimAmount: 0,
  shirtSlimComments: "",
  jacket: "",
  jacketAmount: 0,
  jacketComments: "",
  pants: "",
  pantsAmount: 0,
  pantsComments: "",
  tie: false,
  tieAmount: 0,
  tieComments: "",
  jacketW: "",
  jacketWAmount: 0,
  jacketWComments: "",
  shirtW: "",
  shirtWAmount: 0,
  shirtWComments: "",
  skirt: "",
  skirtAmount: 0,
  skirtComments: "",
  pantsW: "",
  pantsWAmount: 0,
  pantsWComments: "",
});

const getNewChecked = () => ({
  shirt: false,
  shirtSlim: false,
  jacket: false,
  pants: false,
  tie: false,
  jacketW: false,
  shirtW: false,
  skirt: false,
  pantsW: false,
});

const OrderItemInventoryModal = ({
  setModalVisible,
  visible,
  currentItem,
  setCurrentItem,
  setOrderItem,
}) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [inventory, setInventory] = useState(getNewInventory());
  const [checked, setChecked] = useState(getNewChecked());

  useEffect(() => {
    if (currentItem !== null && typeof currentItem !== "undefined") {
      const newInventory = getNewInventory();
      Object.keys(newInventory).forEach((key) => {
        if (
          currentItem[key] !== null &&
          typeof currentItem[key] !== "undefined"
        ) {
          newInventory[key] = currentItem[key];
        }
      });
      setInventory(newInventory);

      const newChecked = getNewChecked();
      Object.keys(newChecked).forEach((key) => {
        if (
          currentItem[key] !== null &&
          typeof currentItem[key] !== "undefined" &&
          currentItem[key] !== "" &&
          currentItem[key] !== false
        ) {
          newChecked[key] = true;
        }
      });
      setChecked(newChecked);
    } else {
      setChecked(getNewChecked());
      setInventory(getNewInventory());
    }
  }, [currentItem]);

  const handleChangeInput = (event) => {
    setErrorMessage(null);
    setInventory({
      ...inventory,
      [event.target.name]: event.target.value,
    });
  };

  const handleToggleCheckbox = (event) => {
    setErrorMessage(null);
    let { name } = event.target;
    if (checked[name]) {
      setInventory((previous) => ({
        ...previous,
        [name]: "",
        [`${name}Amount`]: 0,
        [`${name}Comments`]: "",
      }));
    }
    setChecked((previous) => ({ ...previous, [name]: !previous[name] }));
  };

  const handleTieCheckbox = (event) => {
    setErrorMessage(null);
    const { name } = event.target;
    if (checked[name]) {
      setInventory((previous) => ({
        ...previous,
        [`${name}Amount`]: 0,
      }));
    }
    setChecked((previous) => ({ ...previous, [name]: !previous[name] }));
    setInventory((previous) => ({
      ...previous,
      [name]: !previous[name],
    }));
  };

  const handleCancel = () => {
    setErrorMessage(null);
    setCurrentItem(null);
    setModalVisible(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setErrorMessage(null);

    if (!Object.keys(checked).some((key) => checked[key])) {
      setErrorMessage("Wprowadź asortyment.");
      return;
    }

    const missingSize = ALL_PARAMETERS.filter(
      (parameter) => checked[parameter.name] && inventory[parameter.name] === ""
    );

    if (missingSize.length !== 0) {
      setErrorMessage(
        `Wprowadź rozmiar: ${missingSize.map((x) => x.label).join(", ")}.`
      );
      return;
    }

    const missingAmount = ALL_PARAMETERS.filter(
      (parameter) =>
        checked[parameter.name] &&
        (inventory[`${parameter.name}Amount`] === 0 ||
          inventory[`${parameter.name}Amount`] === "0")
    );

    if (missingAmount.length !== 0) {
      setErrorMessage(
        `Wprowadź ilość: ${missingAmount.map((x) => x.label).join(", ")}.`
      );
      return;
    }

    axios
      .post(`/api/order-items/save-inventory`, {
        id: currentItem.id,
        ...inventory,
      })
      .then((response) => {
        setOrderItem({
          ...response.data,
        });
        setInventory(getNewInventory());
        setChecked(getNewChecked());
        setCurrentItem(null);
        setModalVisible(false);
      })
      .catch((error) => setErrorMessage(error.response.data.message));
  };

  if (!visible) return null;
  if (currentItem == null) return null;

  return (
    <div>
      <Modal isOpen={visible}>
        <ModalBody>
          <Form onSubmit={handleSubmit}>
            {currentItem.gender === "male" && (
              <>
                {MALE_PARAMETERS.map((parameter) => (
                  <div key={parameter.name}>
                    <FormGroup check>
                      <Input
                        checked={checked[parameter.name]}
                        type="checkbox"
                        name={parameter.name}
                        onChange={handleToggleCheckbox}
                      />
                      <Label check>{parameter.label}</Label>
                    </FormGroup>
                    {checked[parameter.name] === true && (
                      <>
                        <FormGroup row>
                          <Col>
                            <Label sm={1}>Rozmiar</Label>
                            <select
                              className="form-select"
                              name={parameter.name}
                              type="select"
                              onChange={handleChangeInput}
                              value={inventory[parameter.name]}
                            >
                              <option value={""}>Wybierz rozmiar</option>
                              {parameter.sizes.map((size) => (
                                <option key={size} value={size}>
                                  {size}
                                </option>
                              ))}
                            </select>
                          </Col>
                          <Col sm={3}>
                            <Label sm={1}>Ilość</Label>
                            <Input
                              type="number"
                              name={`${parameter.name}Amount`}
                              value={inventory[`${parameter.name}Amount`]}
                              onChange={handleChangeInput}
                            />
                          </Col>
                        </FormGroup>
                        <FormGroup>
                          <Col>
                            <Label>Uwagi</Label>
                            <Input
                              style={{ height: 45 }}
                              type="textarea"
                              name={`${parameter.name}Comments`}
                              value={inventory[`${parameter.name}Comments`]}
                              onChange={handleChangeInput}
                            />
                          </Col>
                        </FormGroup>
                      </>
                    )}
                  </div>
                ))}
                <FormGroup check>
                  <Input
                    checked={checked.tie}
                    type="checkbox"
                    name="tie"
                    onChange={handleTieCheckbox}
                  />
                  <Label check>Krawat</Label>
                </FormGroup>
                {checked.tie === true && (
                  <>
                    <Col sm={3}>
                      <Label sm={1}>Ilość</Label>
                      <Input
                        type="number"
                        name={`tieAmount`}
                        value={inventory[`tieAmount`]}
                        onChange={handleChangeInput}
                      />
                    </Col>
                  </>
                )}
              </>
            )}
            {currentItem.gender === "female" &&
              FEMALE_PARAMETERS.map((parameter) => (
                <div key={parameter.name}>
                  <FormGroup check>
                    <Input
                      checked={checked[parameter.name]}
                      type="checkbox"
                      name={parameter.name}
                      onChange={handleToggleCheckbox}
                    />
                    <Label check>{parameter.label}</Label>
                  </FormGroup>
                  {checked[parameter.name] === true && (
                    <>
                      <FormGroup row>
                        <Col>
                          <Label sm={1}>Rozmiar</Label>
                          <select
                            className="form-select"
                            name={parameter.name}
                            type="select"
                            onChange={handleChangeInput}
                            value={inventory[parameter.name]}
                          >
                            <option value={""}>Wybierz rozmiar</option>
                            {parameter.sizes.map((size) => (
                              <option key={size} value={size}>
                                {size}
                              </option>
                            ))}
                          </select>
                        </Col>
                        <Col sm={3}>
                          <Label sm={1}>Ilość</Label>
                          <Input
                            type="number"
                            name={`${parameter.name}Amount`}
                            value={inventory[`${parameter.name}Amount`]}
                            onChange={handleChangeInput}
                          />
                        </Col>
                      </FormGroup>
                      <FormGroup>
                        <Col>
                          <Label>Uwagi</Label>
                          <Input
                            style={{ height: 45 }}
                            type="textarea"
                            name={`${parameter.name}Comments`}
                            value={inventory[`${parameter.name}Comments`]}
                            onChange={handleChangeInput}
                          />
                        </Col>
                      </FormGroup>
                    </>
                  )}
                </div>
              ))}

            {errorMessage && (
              <FormGroup>
                <span style={{ color: "red" }}>{errorMessage}</span>
              </FormGroup>
            )}
          </Form>
        </ModalBody>
        <ModalFooter>
          <Button color="danger" outline onClick={handleCancel}>
            Anuluj
          </Button>
          <Button onClick={handleSubmit}>Dodaj</Button>
        </ModalFooter>
      </Modal>
    </div>
  );
};

export default OrderItemInventoryModal;
